import { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

const useDeviceDetect = () => {
  const [deviceType, setDeviceType] = useState({
    isMobile: false,
    isTablet: false,
  });

  useEffect(() => {
    setDeviceType({ isMobile, isTablet });
  }, []);

  return deviceType;
};

export default useDeviceDetect;
import React, { useState, useRef, useEffect ,startTransition} from 'react';
import { useLocation ,Link,useParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import axios from 'axios';
import Header from "../../component/header/Header";
import Section from '../../component/Stepper/Section';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Footer from '../../component/footer/Footer';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import Button from '@mui/material/Button';
import './Menuitem.css';
import Skeleton from '@mui/material/Skeleton';
import { originalKey } from "./constMenuitem";

const Menuitem = ({ getLocation, headermenu, alldata, themeData, businessHours, carryoutHours, footerData, allLocation, allLocationNavigtion, ourMenu , loading, res_id, mainTheme, webLocationLength,storedEncryptedKey,substoredEncryptedKey,webmenuitemData,loading1}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  const { height, width } = useWindowDimensions();
  const windowWidth = width;
  // const [webmenuitemDataAll, setMenuitem] = React.useState([]);
  // const [loading1, setLoading1] = useState(true);
  // useEffect(() => {
  //   const fetchDataitem = async () => {
  //     try {
  //       const responseAll = await axios.get(`https://www.eatstations.com/api/web_menu_item/${res_id}?category_id=${id}`);
  //       startTransition(() => {
  //         setMenuitem(responseAll.data);
  //         setLoading1(false);
  //       });
  //     } catch (error) {
  //       console.error('Error fetching main location data: ', error);
  //     }
  //   };
  //   fetchDataitem();
  // }, []);
  
  const backgroundStyle = ourMenu.is_page_bg === 1
  ? { backgroundColor: ourMenu.page_bg }
  : {
      backgroundImage: ourMenu.page_bg ? `url(${ourMenu.page_bg})` : 'none'
    };

    if (loading == false && storedEncryptedKey == originalKey) {
        return (
          <Box 
                sx={{  
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
                backgroundSize: 'cover',
                position: 'relative',
                overflow: 'hidden',
                '&::after': {
                  content: '""',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  ...backgroundStyle,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100vw 100vh',
                  zIndex: -1,
                  opacity:'1',
                }
                }}>
                 
                <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
                <>
                  <Box sx={{ mt: '150px', maxWidth:'1368px',width:'95%',margin:'auto',backgroundColor:ourMenu.page_bg}}>
                    <div className="app">                      
                      <div className="content">
                      {loading1 ? (
                        <React.Fragment >
                          <div className='loading_div'>
                          <Skeleton className='skeleton' variant="rectangular" height={50} sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',my:2}}/>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded"
                                className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton menuItemsCard'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                          </Grid>
                          </div>
                        </React.Fragment>
                      ):(
                        <React.Fragment>
                            {webmenuitemData?.Category_list?.map((Category_list, categoryIndex) => (
                              <>
                              {Category_list.id == id && (
                                <>
                                  <div substoredEncryptedKey={substoredEncryptedKey} className="sectionitem">
                                    <Box sx={{marginBottom:'20px',textAlign:'center'}}>
                                      <Card sx={{width:{xs:'100%',sm:'50%',md:'50%',lg:'50%'},margin:'auto',backgroundColor:ourMenu.category_box_color}}>
                                        <Typography variant='h4' 
                                          sx={{
                                            color:ourMenu.category_title_color,
                                            fontFamily:ourMenu.category_title_family,
                                            textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                            paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                          }}>{Category_list.name}</Typography>
                                        <Typography variant='h6' 
                                          sx={{
                                            color:ourMenu.category_title_color,
                                            fontFamily:ourMenu.category_title_family,
                                            fontWeight:400,
                                            textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                            paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                          }}>{Category_list.description}</Typography>
                                      </Card>
                                      <Link to={`/${allLocationNavigtion}/menu#${id}`}>
                                        <Button
                                            aria-label="more"
                                            id="long-button"
                                            aria-haspopup="true"
                                            sx={{mx:0.5,backgroundColor:'#000',borderRadius:'20px',minWidth:'165px',margin:'25px 10px 0px'}} variant="contained" >
                                            <Typography sx={{fontFamily:'inherit',fontSize:'18px',fontFamily:700,textTransform:'capitalize'}}>Back To Menu</Typography>
                                        </Button>
                                      </Link>
                                    </Box>
                                    <Grid container>
                                      {Category_list?.menu_item?.map((menu_item,index)=>(
                                        <Grid item xs={12} sm={12} md={6} lg={4} key={index} 
                                          sx={{
                                            paddingRight:{xs:'6px', sm:'0px', md:'8px', lg:'16px'},
                                            paddingLeft:{xs:'6px', sm:'0px', md:'8px', lg:'16px'},
                                            paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                            paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                                            
                                          }}
                                          >
                                          <Card 
                                            className='menuItemsCard'
                                              sx={{
                                              backgroundColor:ourMenu.item_box_color,
                                              margin:{
                                                xs:'auto',
                                                sm:'auto',
                                                md:'block',
                                                lg:'block'
                                              }
                                            }}
                                            >
                                            <CardMedia
                                              sx={{borderRadius:'8px'}}
                                              component="img"
                                              height="220.922px"
                                              image={windowWidth < 900 ? menu_item.image != '' ? `${menu_item.image}`: `${Category_list.image}` : menu_item.image_medium != '' ? `${menu_item.image_medium}` : `${Category_list.image}`}
                                              alt={`${menu_item.name}`}
                                            />
                                            <CardContent>                                              
                                              <Typography sx={{color:ourMenu.item_title_color,fontWeight:700,fontSize:'20px',fontFamily:`${ourMenu.category_title_family}`}}>
                                                {menu_item.name}  
                                                {menu_item.is_popular_item == 1 && (<span className='popular_div'>Popular</span> )}
                                              </Typography>                                               
                                              <Typography variant='p' sx={{fontSize:'14px',fontWeight:'300',color:ourMenu.item_des_color,fontFamily:`${ourMenu.item_des_family}`}}>
                                                {menu_item.description}
                                              </Typography>                                               
                                                <Typography className='itemname' sx={{color:ourMenu.item_title_color,fontWeight:400,fontSize:'19px'}}>{menu_item.price != '' ? '$':''}
                                                  {menu_item.price}
                                                  {menu_item.is_gluten_free == 1 && ( <img class="Gluten_vegan_img" src={"/Gluten.png"}/> )}
                                                  {menu_item.is_vegan == 1 && ( <img class="Gluten_vegan_img" src={"/vegan.png"}/> )}
                                                </Typography>                    
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </div>
                                </>
                              )}
                              </>
                            ))}
                        </React.Fragment>
                      )}
                      </div>
                    </div>
                  </Box>
                </>
                <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                
              </Box>
                   
        );
      }else{
        return null;
      }
    
}

export default Menuitem;

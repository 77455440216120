import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {Container } from "@mui/material";
import { useSelector } from "react-redux";
import { currentDay } from "./days";
import { currentDay1 } from "./days";
import { suboriginalKey } from "./days";
import ButtonGroup from '@mui/material/ButtonGroup';
import Map from "../map/Map";
import useWindowDimensions from "../../customHook/useWindowDimensions/useWindowDimensions ";
import './Footer.css'
const Footer = ({getLocation, businessHours, carryoutHours, themeData, footerData, allLocation, allLocationNavigtion , loading, substoredEncryptedKey, mainTheme})=> {
    const { height, width } = useWindowDimensions();
    const windowWidth = width;
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState('');
    const handleClickOpen = (index) => {
        setSelectedIndex(index);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const currentUrl = useLocation();
    const defaultCenter = {
        lat: parseFloat(getLocation?.latitude),
        lng: parseFloat(getLocation?.longitude)
    };
    const footerREDColor = [
        {
            HEX : '#FF0000'
        },
        {
            HEX : '#8B0000'
        },
        {
            HEX : '#B22222'
        },
        {
            HEX : '#DC143C'
        },
        {
            HEX : '#CD5C5C'
        },
        {
            HEX : '#F08080'
        },
        {
            HEX : '#FA8072'
        },
        {
            HEX : '#E9967A'
        },
        {
            HEX : '#FFA07A'
        },
        {
            HEX : '#FF6347'
        },
        {
            HEX : '#FF7F50'
        },
        {
            HEX : '#FF4500'
        },
        {
            HEX : '#C71585'
        },
        {
            HEX : '#DB7093'
        },
        {
            HEX : '#FFB6C1'
        },
        {
            HEX : '#FF69B4'
        },
        {
            HEX : '#FF1493'
        },
        {
            HEX : '#800000'
        },
        {
            HEX : '#A52A2A'
        },
        {
            HEX : '#CB4154'
        },
        {
            HEX : '#FF0800'
        },
        {
            HEX : '#FF007F'
        },
        {
            HEX : '#E0115F'
        },
        {
            HEX : '#FF2400'
        },
        {
            HEX : '#800020'
        },
        {
            HEX : '#560319'
        },
        {
            HEX : '#B7410E'
        },
        {
            HEX : '#660000'
        },
        {
            HEX : '#990000'
        },
    ]
    let footerREDColorCloseNow;
    footerREDColor.map((footerREDColor)=>{
        if (footerREDColor.HEX == themeData.footer_title_font_color) {
            footerREDColorCloseNow='#000';
        } else {
            footerREDColorCloseNow='#ff0000';
        }
    })
    if (loading === false && substoredEncryptedKey == suboriginalKey) {
        return (
            <React.Fragment>
                {substoredEncryptedKey == suboriginalKey && (
                    <React.Fragment>
                        <Box id='Contact' sx={{px:2,backgroundColor:`${themeData?.footer_backgrouund_color}`,padding:{xs:'0',sm:'0',md:'0 1%',lg:'0 1%',xl:'0 5%'}}}>
                            <Grid style={{padding:'10px 0'}} container>
                                <Grid xs={12} sm={12} md={6} lg={3} sx={{padding:{xs:'5px 8px',sm:'0',md:'20px',lg:'20px'}}}>
                                    <Box sx={{height:'240px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',border:`0.2px solid`,borderColor:`${themeData?.footer_title_font_color}`,borderRadius:'5px'}}>
                                        <Box sx={{width:'100%',display:"flex",flexDirection:{xs:'column',sm:'row',md:'column',xl:'column',justifyContent:'center',alignItems:'center'}}}>
                                            <Box sx={{flex:{xs:'100%',sm:'50%'}}}>
                                                <Typography 
                                                    sx={{
                                                        my:1,
                                                        textAlign:{xs:'center',sm:'center', md:'center'},
                                                        color:`${themeData?.footer_title_font_color}`,
                                                        fontFamily:`${themeData?.font_style}`,
                                                        fontWeight:800
                                                    }}
                                                    variant="h4"
                                                    >Location</Typography>
                                                <Box sx={{display:'flex',alignItems:'center',justifyContent:{xs:'center',sm:'center',md:'center'}}}>
                                                    <LocationOnIcon sx={{color:`${themeData?.footer_title_details_color}`}}/>
                                                    <Box>   
                                                        <address 
                                                            style={{
                                                                color:`${themeData?.footer_title_details_color}`,
                                                                fontFamily:`${themeData?.font_style}`,
                                                                fontSize:'14px',
                                                            }}>{getLocation?.address_line_1}</address>
                                                        <address 
                                                            style={{
                                                                color:`${themeData?.footer_title_details_color}`,
                                                                fontFamily:`${themeData?.font_style}`,
                                                                fontSize:'14px',
                                                                }}>{getLocation?.address_line_2}</address>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{flex:{xs:'100%',sm:'50%'}}}>
                                                <Typography
                                                    sx={{
                                                        textAlign:{xs:'center',sm:'center',md:'center'},
                                                        my:1,
                                                        color:`${themeData?.footer_title_font_color}`,
                                                        fontFamily:`${themeData?.font_style}`,
                                                        fontWeight:800
                                                    }}
                                                    variant="h4"
                                                    >Contact us</Typography>
                                                    {getLocation?.phone != null && (
                                                        <Box sx={{display:'flex',alignItems:'center',justifyContent:{xs:'center',sm:'center',md:'center'}}}>
                                                            <a
                                                                href={`tel:${getLocation?.phone}`}
                                                                target="_block"
                                                                style={{color:`${themeData?.footer_title_details_color}`,textDecoration:'none',display:'flex',alignItems:'center'}}
                                                                >
                                                                <CallIcon />
                                                                <Typography 
                                                                    sx={{
                                                                        mx:1,
                                                                        fontFamily:`${themeData?.font_style}`,
                                                                        fontSize:'14px',
                                                                    }}>{getLocation?.phone}</Typography>
                                                            </a>
                                                        </Box>
                                                     )}
                                                {getLocation.email != null && (
                                                    <Box sx={{display:'flex',alignItems:'center', my:1,justifyContent:{xs:'center',sm:'center',md:'center'}}}>
                                                        <a
                                                            href={`mailto:${getLocation?.email}`}
                                                            target="_block"
                                                            style={{color:`${themeData?.footer_title_details_color}`,textDecoration:'none',display:'flex',alignItems:'center'}}
                                                            >
                                                            <MailIcon />
                                                            <Typography 
                                                                sx={{
                                                                    mx:1,
                                                                    fontFamily:`${themeData?.font_style}`,
                                                                    fontSize:'14px',
                                                                }}>{getLocation?.email}</Typography>
                                                        </a>
                                                    </Box>
                                                )}
                                                <Box sx={{my:1,textAlign:{xs:'center',sm:'center', md:'center'}}}>
                                                    {getLocation.facebook_url != null && (
                                                        <>
                                                            <a
                                                                href={getLocation?.facebook_url}
                                                                target="_block"
                                                                style={{color:`${themeData?.footer_title_details_color}`,textDecoration:'none'}}
                                                                >
                                                                <FacebookIcon />
                                                            </a>
                                                        </>
                                                    )}
                                                    
                                                    {getLocation.instagram_url != null && (
                                                        <>
                                                        <a
                                                            href={getLocation?.instagram_url}
                                                            target="_block"
                                                            style={{color:`${themeData?.footer_title_details_color}`,textDecoration:'none',marginLeft:'8px',marginRight:'8px'
                                                        }} 
                                                            >
                                                            <InstagramIcon />
                                                        </a>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid sx={{display:{xs:'block',sm:'block',md:'block',lg:'none'},textAlign:'center',padding:{xs:'0',sm:'0',md:'20px',lg:'20px'}}} xs={12} sm={12} md={6} lg={3}>
                                    <Map defaultCenter={defaultCenter}/>
                                </Grid>
                                {/* Business Hours */}
                                <Grid xs={12} sm={6} md={6} lg={3} sx={{px:1,padding:{xs:'0 8px',sm:'0 2px 0 5px',md:'20px',lg:'20px'}}}>
                                    <Typography 
                                        sx={{
                                            my:1,
                                            textAlign:'center',
                                            color:`${themeData?.footer_title_font_color}`,
                                            fontFamily:`${themeData?.font_style}`,
                                            fontWeight:800
                                        }}
                                        variant="h4"
                                        >Business Hours</Typography>
                                    <Accordion 
                                        sx={{
                                            width:{xs:'100%',sm:'100%',md:'100%',lg:'100%'},
                                            margin:{xs:'auto !important',sm:'auto !important',md:'auto !important',lg:'auto !important'},
                                            backgroundColor:`${themeData?.footer_backgrouund_color}`,color:`${themeData?.footer_backgrouund_color}`
                                        }}>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                            className="panel2-header"
                                            sx={{backgroundColor:`${themeData?.footer_title_font_color}`,borderRadius:'5px'}}
                                            >
                                            {businessHours?.map(businessHours =>(
                                                <>
                                                    {businessHours.current_day === 1 && (
                                                        <Box>
                                                            <Box sx={{display:'flex',alignItems:'center'}}>
                                                                <CalendarMonthIcon/>
                                                                <Typography
                                                                    sx={{
                                                                        mx:{sm:2,md:1,xs:1},
                                                                        fontFamily:`${themeData?.font_style}`,
                                                                        fontSize:'14px',
                                                                        fontWeight:600,
                                                                    }}
                                                                    >{businessHours?.full_work_day}</Typography>
                                                                <Typography sx={{fontSize:'14px'}}>|</Typography>
                                                                <Typography 
                                                                    sx={{
                                                                        mx:{sm:2,md:1,xs:1},
                                                                        color:`${footerREDColorCloseNow} !important`,
                                                                        fontFamily:`${themeData?.font_style}`,
                                                                        fontSize:'14px',
                                                                        fontWeight:600
                                                                    }}>{footerData?.business_status} now</Typography>
                                                            </Box>
                                                            {businessHours.is_slot === 'open' && (
                                                                <>
                                                                    {businessHours.workdays_type == 1 ? (
                                                                        <>                                                                        
                                                                            {businessHours.is_breakfast === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center'}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Breakfast</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.breakfast_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.breakfast_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                            {businessHours.is_lunch === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center'}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Lunch</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.lunch_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.lunch_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                            {businessHours.is_dinner === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center'}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Dinner</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.dinner_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.dinner_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}                                                                        
                                                                        </>
                                                                    ):(
                                                                        <>                                                                    
                                                                            <Box sx={{display:'flex',alignItems:'center'}}>
                                                                                <Grid container>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.start_hour}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1}>
                                                                                        <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.end_hour}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>                                                                                                                   
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {businessHours.is_slot === 'close' && (
                                                                <Box sx={{display:'flex'}}>
                                                                    <Typography sx={{mx:1,color:`${footerREDColorCloseNow} !important`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>Closed</Typography>
                                                                </Box>
                                                            )}  
                                                        </Box>
                                                    )}
                                                </>
                                            ))}
                                        </AccordionSummary>
                                        <AccordionDetails sx={{p:0}}>
                                            {businessHours?.map(businessHours =>(
                                                <>
                                                        <Typography sx={{my:1,border:`0.4px solid ${themeData?.footer_title_details_color}`,p:1,borderRadius:'5px'}}>
                                                            <Box sx={{display:'flex'}}>
                                                            {businessHours.current_day == 1 ? <CheckBoxIcon sx={{color:`${themeData?.footer_title_details_color}`}} /> :<BrightnessLowIcon sx={{color:`${themeData?.footer_title_details_color}`}} />}
                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>{businessHours.work_day}</Typography>
                                                            </Box>
                                                            {businessHours.is_slot === 'open' && (
                                                                <>
                                                                {businessHours.workdays_type == 1 ? (
                                                                    <>
                                                                    {businessHours.is_breakfast === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Breakfast</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.breakfast_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.breakfast_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                    {businessHours.is_lunch === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Lunch</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.lunch_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.lunch_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                    {businessHours.is_dinner === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Dinner</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.dinner_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{businessHours.dinner_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                    </>
                                                                ):(
                                                                    <>
                                                                        <Box sx={{display:'flex',alignItems:'center'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px',color:`${themeData?.footer_title_details_color}`}}>{businessHours.start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px',color:`${themeData?.footer_title_details_color}`}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px',color:`${themeData?.footer_title_details_color}`}}>{businessHours.end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>                                                                       
                                                                    </>
                                                                )}
                                                                </>
                                                            )}
                                                            {businessHours.is_slot === 'close' && (
                                                                <Box sx={{display:'flex'}}>
                                                                    <Typography sx={{mx:1,color:`${footerREDColorCloseNow} !important`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>Closed</Typography>
                                                                </Box>
                                                            )}  
                                                        </Typography>
                                                    
                                                </>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/* Carryout Hours */}
                                <Grid xs={12} sm={6} md={6} lg={3} sx={{px:1,padding:{xs:'0 8px',sm:'0 5px 0 2px',md:'20px',lg:'20px'}}}>
                                    <Typography sx={{my:1,textAlign:'center',color:`${themeData?.footer_title_font_color}`,fontFamily:`${themeData?.font_style}`,fontWeight:800}} variant="h4">Carryout Hours</Typography>
                                    <Accordion 
                                        sx={{
                                            width:{xs:'100%',sm:'100%',md:'100%',lg:'100%'},
                                            margin:{xs:'auto !important',sm:'auto !important',md:'auto !important',lg:'auto !important'},
                                            backgroundColor:`${themeData?.footer_backgrouund_color}`,color:`${themeData?.footer_backgrouund_color}`
                                        }}>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                            className="panel2-header"
                                            sx={{backgroundColor:`${themeData?.footer_title_font_color}`,borderRadius:'5px'}}
                                            >
                                            {carryoutHours?.map(carryoutHours =>(
                                                <>
                                                    {carryoutHours.current_day === 1 && (
                                                        <Box>
                                                            <Box sx={{display:'flex',alignItems:'center'}}>
                                                                <CalendarMonthIcon/>
                                                                <Typography sx={{mx:{sm:1,md:1,xs:1},fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>{carryoutHours?.full_work_day}</Typography>
                                                                <Typography sx={{fontSize:'14px'}}>|</Typography>
                                                                <Typography sx={{mx:{sm:1,md:1,xs:1},color:`${footerREDColorCloseNow} !important`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>{footerData.carrout_status} now</Typography>
                                                            </Box>
                                                            {carryoutHours.is_slot === 'open' && (
                                                                <>
                                                                    {carryoutHours.carryout_type == 1 ? (
                                                                        <>
                                                                            {carryoutHours.is_breakfast === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center',}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Breakfast</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.breakfast_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.breakfast_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                            {carryoutHours.is_lunch === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center',}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Lunch</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.lunch_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.lunch_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                            {carryoutHours.is_dinner === 1 && (
                                                                                <Box sx={{display:'flex',alignItems:'center',}}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={2}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Dinner</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.dinner_start_hour}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.dinner_end_hour}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            )}
                                                                        
                                                                        </>
                                                                    ):(
                                                                        <Box sx={{display:'flex',alignItems:'center',}}>
                                                                            <Grid container>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                </>
                                                            )}
                                                             {carryoutHours.is_slot === 'close' && (
                                                                <Box sx={{display:'flex'}}>
                                                                    <Typography sx={{mx:1,color:`${footerREDColorCloseNow} !important`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>Closed</Typography>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    )}
                                                </>
                                            ))}
                                        </AccordionSummary>
                                        <AccordionDetails  sx={{p:0}}>
                                            {carryoutHours?.map(carryoutHours =>(
                                                <>                                          
                                                    <Typography sx={{my:1,border:`0.4px solid ${themeData?.footer_title_details_color}`,p:1,borderRadius:'5px'}}>
                                                        <Box sx={{display:'flex'}}>
                                                        {carryoutHours.current_day == 1 ? <CheckBoxIcon sx={{color:`${themeData?.footer_title_details_color}`}} /> :<BrightnessLowIcon sx={{color:`${themeData?.footer_title_details_color}`}} />}
                                                            <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>{carryoutHours.work_day}</Typography>
                                                        </Box>
                                                        {carryoutHours.is_slot === 'open' && (
                                                            <>
                                                                {carryoutHours.carryout_type == 1 ? (
                                                                    <>
                                                                    {carryoutHours.is_breakfast === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Breakfast</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.breakfast_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.breakfast_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                    {carryoutHours.is_lunch === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Lunch</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.lunch_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.lunch_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}
                                                                    {carryoutHours.is_dinner === 1 && (
                                                                        <Box sx={{display:'flex'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={2}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>Dinner</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>:</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.dinner_start_hour}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.dinner_end_hour}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )}                                                                            
                                                                    </>
                                                                ):(
                                                                    <Box sx={{display:'flex'}}>
                                                                        <Grid container>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.start_hour}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={1}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>-</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Typography sx={{mx:{sm:1,md:1,xs:0},color:`${themeData?.footer_title_details_color}`,fontFamily:`${themeData?.font_style}`,fontSize:'14px'}}>{carryoutHours.end_hour}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                )}
                                                            </>
                                                        )}
                                                        {carryoutHours.is_slot === 'close' && (
                                                            <Box sx={{display:'flex'}}>
                                                                <Typography sx={{mx:1,color:'red',fontFamily:`${themeData?.font_style}`,fontSize:'14px',fontWeight:600}}>Closed</Typography>
                                                            </Box>
                                                        )}
                                                    </Typography> 
                                                </>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid sx={{display:{xs:'none',sm:'none',md:'none',lg:'block'},padding:{xs:'0',sm:'0',md:'20px',lg:'20px'}}} xs={12} sm={12} md={6} lg={3}>
                                    <Map defaultCenter={defaultCenter} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{px:2,backgroundColor:`${themeData?.footer_backgrouund_color}`,padding:{xs:'0',sm:'0',md:'0 5%',lg:'0 5%'},borderTop:`2px solid ${themeData?.footer_title_details_color}`,marginBottom:mainTheme.layout == 4 ? windowWidth < 600 ?'100px':'':''}}>
                            {windowWidth < 600 ? ( 
                                <> 
                                    <div style={{height:'90px',padding:'5px 10px',marginBottom: mainTheme.layout == 1 || mainTheme.layout == 5 ? '0px' : '55px'}}>                                                       
                                        <a class="footerphonetext" style={{
                                            color:`${themeData?.footer_title_details_color}`,
                                            fontFamily:`${themeData?.font_style}`,
                                            textDecoration:'none',
                                            textTransform:'capitalize',
                                            alignItems:'center',
                                            }} onClick={() => handleClickOpen(1)}>Privacy and Policy</a>                          
                                        <a class="footerphonetext" style={{
                                            color:`${themeData?.footer_title_details_color}`,
                                            fontFamily:`${themeData?.font_style}`,
                                            textDecoration:'none',
                                            textTransform:'capitalize',
                                            alignItems:'center',
                                            }} onClick={() => handleClickOpen(2)}> | Terms & Conditions of Use</a>
                                        <a class="footerphonetext" style={{
                                            color:`${themeData?.footer_title_details_color}`,
                                            fontFamily:`${themeData?.font_style}`,
                                            textDecoration:'none',
                                            textTransform:'capitalize',
                                            alignItems:'center',
                                            }} onClick={() => handleClickOpen(3)}> | Accessibility</a>
                                        <Box sx={{justifyContent: 'left',display: 'flex',marginTop:"8px"}}>
                                            <>
                                                <a class="footerphonetext" style={{
                                                color:`${themeData?.footer_title_details_color}`,                                            
                                                fontFamily:`${themeData?.font_style}`,
                                                textDecoration:'none',
                                                textTransform:'capitalize',marginRight:"10px",                                           
                                                alignItems:'center',
                                                }} href="#">Powered By Eatstations</a>
                                                {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                                    <a href="#">
                                                        <Box
                                                            sx={{
                                                                width:{xs:'100px',sm:'100px',md:'165px',lg:'165px'},
                                                                height:{xs:'24px',sm:'24px',md:'40px',lg:'40px'},
                                                                position:'absolute',right:"20px",
                                                                backgroundImage:`url(${process.env.PUBLIC_URL + '/positivessl.png'})`,
                                                                backgroundSize:'cover',backgroundRepeat:'no-repeat'
                                                            }} />
                                                    </a>
                                                ):(
                                                    <Link to={`/${allLocationNavigtion}#`}>
                                                        <Box 
                                                            sx={{width:{xs:'100px',sm:'100px',md:'165px',lg:'165px'},height:{xs:'24px',sm:'24px',md:'40px',lg:'40px'},position:'absolute',right:"20px",backgroundImage:`url(${process.env.PUBLIC_URL + '/positivessl.png'})`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}} />
                                                    </Link>
                                                )}
                                            </>
                                        </Box>
                                    </div>
                                   
                                </>                                
                            ):(
                                <Container sx={{position:'relative',display:'flex',justifyContent:'center',alignItems:'center',height:{xs:'auto',sm:'auto',md:'60px',xl:'60px'}}}>
                                    <Box sx={{display:{lg:'flex'},flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},justifyContent:'space-between',width:'100%'}}>
                                        <Box className='fotterPrivacyandPolicyMenu' sx={{py:1,display:{xs:'grid',sm:'flex',md:'flex',lg:'flex'},flexDirection:{xs:'column',sm:'row',md:'row',lg:'row',justifyContent:'center',gridTemplateColumns:'repeat(3, 1fr)'}}}>
                                            <a className="fotterPrivacyandPolicyMenuA" style={{
                                                color:`${themeData?.footer_title_details_color}`,
                                                fontWeight:700,
                                                fontFamily:`${themeData?.font_style}`,
                                                textDecoration:'none',
                                                textTransform:'capitalize',
                                                fontSize:'14px',
                                                display:'flex',
                                                alignItems:'center',
                                                gridColumn:'1 / 2',
                                                }} href="#">Powered By Eatstations</a>
                                            {footerData && footerData.infopage && footerData.infopage?.map((infopage,index) => (
                                                <>
                                                    {infopage.static_id == 1 && (
                                                        <Button 
                                                            key={infopage} 
                                                            sx={{
                                                                gridColumn:'2 / 3',
                                                                mx:{xs:0,sm:0,md:2,lg:2},
                                                                borderRadius:'0',
                                                                borderLeft:`1px solid ${themeData?.footer_title_details_color}`,lg:`1px solid ${themeData?.footer_title_details_color}`
                                                            }}
                                                            onClick={() => handleClickOpen(infopage.static_id)}
                                                            >
                                                            <Typography 
                                                            sx={{
                                                                color:`${themeData?.footer_title_details_color}`,
                                                                fontWeight:700,
                                                                fontFamily:`${themeData?.font_style}`,
                                                                textDecoration:'none',
                                                                textTransform:'capitalize',
                                                                fontSize:{xs:'8px',sm:'8px',md:'14px',lg:'14px'}
                                                                }}>
                                                            {infopage.title}
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                    {infopage.static_id == 2 && (
                                                        <Button 
                                                            key={infopage}
                                                            sx={{
                                                                gridColumn:'3 / 4',
                                                                mx:{xs:0,sm:0,md:2,lg:2},
                                                                borderRadius:'0',
                                                                borderLeft:`1px solid ${themeData?.footer_title_details_color}`,lg:`1px solid ${themeData?.footer_title_details_color}`
                                                            }}
                                                            onClick={() => handleClickOpen(infopage.static_id)}
                                                            >
                                                            <Typography 
                                                            sx={{
                                                                color:`${themeData?.footer_title_details_color}`,
                                                                fontWeight:700,
                                                                fontFamily:`${themeData?.font_style}`,
                                                                textDecoration:'none',
                                                                textTransform:'capitalize',
                                                                fontSize:{xs:'8px',sm:'8px',md:'14px',lg:'14px'}
                                                                }}>
                                                            {infopage.title}
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                    {infopage.static_id == 3 && (
                                                        <Button 
                                                            key={infopage} 
                                                            sx={{
                                                                gridColumn:'1 / 2',
                                                                mx:{xs:0,sm:0,md:2,lg:2},
                                                                borderRadius:'0',
                                                                borderLeft:`1px solid ${themeData?.footer_title_details_color}`,lg:`1px solid ${themeData?.footer_title_details_color}`
                                                            }}
                                                            onClick={() => handleClickOpen(infopage.static_id)}
                                                            >
                                                            <Typography 
                                                            sx={{
                                                                color:`${themeData?.footer_title_details_color}`,
                                                                fontWeight:700,
                                                                fontFamily:`${themeData?.font_style}`,
                                                                textDecoration:'none',
                                                                textTransform:'capitalize',
                                                                fontSize:{xs:'8px',sm:'8px',md:'14px',lg:'14px'}
                                                                }}>
                                                            {infopage.title}
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                </>
                                            ))}
                                        </Box>
                                        <Box 
                                            className='positivessl_footer_img'
                                            sx={{
                                                position:{xs:'unset',sm:'unset',md:'absolute',xl:'absolute'},
                                            }}>
                                            <>
                                                {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                                    <a href="#">
                                                        <Box
                                                            sx={{
                                                                width:{xs:'100px',sm:'100px',md:'165px',lg:'165px'},
                                                                height:{xs:'24px',sm:'24px',md:'40px',lg:'40px'},
                                                                backgroundImage:`url(${process.env.PUBLIC_URL + '/positivessl.png'})`,
                                                                backgroundSize:'cover',backgroundRepeat:'no-repeat'
                                                            }} />
                                                    </a>
                                                ):(
                                                    <Link to={`/${allLocationNavigtion}#`}>
                                                        <Box 
                                                            sx={{width:{xs:'100px',sm:'100px',md:'165px',lg:'165px'},height:{xs:'24px',sm:'24px',md:'40px',lg:'40px'},backgroundImage:`url(${process.env.PUBLIC_URL + '/positivessl.png'})`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}} />
                                                    </Link>
                                                )}
                                            </>
                                        </Box>
                                    </Box>
                                </Container>
                            )}
                            
                        </Box>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {mainTheme.layout == 1 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleClose}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                            {mainTheme.layout == 2 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'50%',
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    padding:0,
                                    margin:0,
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleClose}
                                color='error' 
                                variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                            ) : null}
                            {mainTheme.layout == 3 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleClose}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                             {mainTheme.layout == 4 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'50%',
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    padding:0,
                                    margin:0,
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleClose}
                                color='error' 
                                variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                            ) : null}
                            {mainTheme.layout == 5 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleClose}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                            <DialogTitle id="alert-dialog-title">
                            {footerData && footerData.infopage && footerData.infopage?.map((infopage,index) => (
                                <>
                                {infopage.static_id == selectedIndex && (
                                    <>
                                    <Typography variant="h4">
                                        {infopage?.title}
                                    </Typography>
                                    </>
                                )}
                                </>
                            ))}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                {footerData && footerData.infopage && footerData.infopage?.map((infopage,index) => (
                                    <>
                                        {infopage.static_id == selectedIndex && (
                                            <>
                                                <Typography dangerouslySetInnerHTML={{ __html: infopage?.content }} />
                                            </>
                                        )}
                                    </>
                                ))}
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
    else{
        return null;
    }
}

export default Footer;
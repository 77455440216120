import { call, put, takeEvery, all } from 'redux-saga/effects';
import axios from 'axios';
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../features/API/apiSlice';

function* fetchDefaultLocation() {
  try {
    yield put(fetchDataStart());
    const res_id = localStorage.getItem('resid');
    if (!res_id) {
      throw new Error('Restaurant ID not found in localStorage');
    }
    const getreslistResponse = yield call(axios.get, `https://www.eatstations.com/api/get_res_list?res_id=${res_id}`);
    const webmenuitemResponse = yield call(axios.get, `https://www.eatstations.com/api/web_menu_item/${res_id}`);
    const webmenuitemResponseSlotDefault = yield call(axios.get, `https://www.eatstations.com/api/web_menu_item/${res_id}?${webmenuitemResponse.data.get_slot}`);
    const webmenuitemResponseSlot1 = yield call(axios.get, `https://www.eatstations.com/api/web_menu_item/${res_id}?slot=1`);
    const webmenuitemResponseSlot2 = yield call(axios.get, `https://www.eatstations.com/api/web_menu_item/${res_id}?slot=2`);
    const webmenuitemResponseSlot3 = yield call(axios.get, `https://www.eatstations.com/api/web_menu_item/${res_id}?slot=3`);

    yield put(fetchDataSuccess({
        getreslistData1: getreslistResponse.data,
        webmenuitemData1: webmenuitemResponse.data,
        webmenuitemDataSlotDefault: webmenuitemResponseSlotDefault.data,
        webmenuitemDataSlot11: webmenuitemResponseSlot1.data,
        webmenuitemDataSlot21:webmenuitemResponseSlot2.data,
        webmenuitemDataSlot31:webmenuitemResponseSlot3.data,
    }));
  } catch (error) {
    yield put(fetchDataFailure(error.message));
  }
}

function* watchFetchRequests() {
  yield takeEvery('api/fetchDefaultLocation', fetchDefaultLocation);
}

export default function* rootSaga() {
  yield all([
    watchFetchRequests(),
  ]);
}

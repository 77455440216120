import React from "react";
import useDeviceDetect from "../../customHook/deviceDetect/useDeviceDetect";
import { Box, Button, Typography } from "@mui/material";
const AppDownload = ()=> {
    const {isMobile, isTablet} = useDeviceDetect()
    return(
        <>
            {(isMobile || isTablet) ? (
                <Box sx={{
                    display:"flex",
                    alignItems:'center',
                    justifyContent:'space-between',
                    p:1,
                    backgroundColor:'antiquewhite'
                }}>
                    <Box sx={{
                        display:'flex',
                        alignItems:'center'
                    }}>
                        <Box sx={{borderRadius:'3px'}}>
                            <img style={{borderRadius:'3px'}} src={`${process.env.PUBLIC_URL}/AppIcon-20@2x.png`} width={40} height={40} alt="AppIcon"/>
                        </Box>
                        <Box sx={{px:1}}>
                            <Typography variant="h2" sx={{fontSize:'17px',fontWeight:700,lineHeight:1.1}}>Eat Stations - Food Tech</Typography>
                            <Typography variant="p" sx={{fontSize:'13px',fontWeight:500,lineHeight:1.1}}>Open in the Eat Stations app</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <a href="https://eatstationsapp.page.link/myapp" target="_black">
                            <Button color="error" variant="contained" sx={{borderRadius:'18px'}}>Open</Button>
                        </a>
                    </Box>
                </Box>
            ) : null}
        </>
    );
}
export default AppDownload;
import React, { useEffect } from "react";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button, Card, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { Link, useLocation } from "react-router-dom";
import { originalKey } from "./constLocation";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
const Location = ({getLocation, headermenu, alldata, themeData, businessHours, carryoutHours, footerData, allLocation, allLocationNavigtion, home, webmenuitemData ,loading, substoredEncryptedKey, storedEncryptedKey, mainTheme, webLocationLength})=> {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    if (loading == false && originalKey === storedEncryptedKey) {
        return(
            <Box sx={{backgroundColor:'#fff'}}>
            <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} loading={loading} allLocationNavigtion={allLocationNavigtion} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
            {home?.map((homeDate)=>(
                <>
                    {homeDate.section_id == 6 && (
                        <Container sx={{marginTop:'120px'}}>
                            <Typography variant="h3" sx={{color:'#000',textAlign:'center'}}>
                                Location
                            </Typography>
                            <Box>
                                {homeDate.data?.map((LocationData)=>(
                                    <>
                                        <Card sx={{my:2,border:'0.8px solid #eee'}}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <img style={{width:'100%',height:'100%'}} src={`${LocationData?.image}`} alt='image is not found'/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <Box sx={{py:2,height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                                                        <Typography variant="h5">{LocationData.title}</Typography>
                                                        <address>{LocationData?.address_line_1}</address>
                                                        <address>{LocationData?.address_line_2}</address>
                                                        <Box sx={{display:'flex'}}>
                                                            <EmailIcon sx={{color:'#f1592a'}}/>
                                                            <Typography sx={{mx:1}}>{LocationData?.email}</Typography>
                                                        </Box>
                                                        <Box sx={{display:'flex'}}>
                                                            <CallIcon sx={{color:'#f1592a'}}/>
                                                            <Typography sx={{mx:1}}>{LocationData?.phone}</Typography>
                                                        </Box>
                                                        <a href={`/${LocationData?.url}`}>
                                                            {mainTheme?.layout == 5 && (
                                                                <ButtonGroup
                                                                    disableElevation
                                                                    variant="contained"
                                                                    aria-label="Disabled button group"
                                                                >
                                                                    <Button
                                                                        sx={{
                                                                            my:1.5,
                                                                            backgroundColor:`${themeData?.button_color}`,
                                                                            color:`${themeData?.button_font_color}`,
                                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                                            fontWeight:600,
                                                                            '&:hover': {
                                                                            backgroundColor:`${themeData?.button_font_color}`,
                                                                            color:`${themeData?.button_color}`,
                                                                            border:`1px solid ${themeData?.button_color}`,
                                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                                            }
                                                                        }}
                                                                    >
                                                                        MORE INFO
                                                                    </Button>
                                                                    <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                                </ButtonGroup>
                                                            )}
                                                            {mainTheme.layout == 1 ? (
                                                                <Button 
                                                                    sx={{
                                                                    backgroundColor:`${themeData?.button_color}`,
                                                                    color:`${themeData?.button_font_color}`,
                                                                    borderRadius:'50px',
                                                                    fontWeight:600,
                                                                    '&:hover': {
                                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                                        color:`${themeData?.button_color}`,
                                                                        border:`1px solid ${themeData?.button_color}`,
                                                                        borderRadius:'50px',
                                                                    }
                                                                    }}>
                                                                        MORE INFO
                                                                </Button>
                                                            ) : null}
                                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                                <Button 
                                                                    sx={{
                                                                    backgroundColor:`${themeData?.button_color}`,
                                                                    color:`${themeData?.button_font_color}`,
                                                                    borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                                    fontWeight:600,
                                                                    '&:hover': {
                                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                                        color:`${themeData?.button_color}`,
                                                                        border:`1px solid ${themeData?.button_color}`,
                                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                                        '& .ads-click-icon': {
                                                                            color: `${themeData?.button_color}`,
                                                                        }
                                                                    }
                                                                    }}>
                                                                        {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                                        MORE INFO
                                                                </Button>
                                                            ) : null}
                                                        </a>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </>
                                ))}
                            </Box>
                        </Container>
                    )}
                </>
            ))}
            <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
            </Box>
        )
    }else{
        return null;
    }
}

export default Location;
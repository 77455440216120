import React, { useEffect } from "react";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useLocation } from "react-router-dom";
import Carousel from "../../component/carousel/Carousel";
import { storedEncryptedKey } from "./constViewAll";
import { originalKey } from "./constViewAll";
const ViewAll = ({getLocation, headermenu, alldata, themeData, businessHours, carryoutHours, footerData, allLocation, allLocationNavigtion, home, loading ,substoredEncryptedKey,storedEncryptedKey, mainTheme, webLocationLength})=> {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    if (loading == false && originalKey == storedEncryptedKey) {
        return (
            <>
                <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
                <Box sx={{backgroundColor:'#000',height:{xs:'auto',sm:'auto',md:'700px',lg:'700px'}}}>
                    <Grid container sx={{height:'100%'}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%',display:{xs:'none',sm:'none',md:'block',lg:'block'}}}>
                            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'100%'}}>
                                <Box>
                                    <Typography variant="h3" sx={{color:'#fff',fontWeight:700}}>{alldata.user.name}</Typography>
                                </Box>
                                <Box>
                                    <ul style={{listStyle:'none',display:'flex',alignItems:'center'}}>
                                        <li style={{margin:'5px'}}>
                                            <Link to={`/${allLocationNavigtion}`} style={{textDecoration:'none'}}>
                                                <Typography
                                                    sx={{
                                                        color:'#fff',
                                                        display:'flex',
                                                        alignItems:'center',
                                                        '&:hover':{
                                                            color:'red'
                                                        }
                                                    }}>
                                                    Home
                                                    <ArrowForwardIosIcon />
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li style={{margin:'5px'}}>
                                            <Typography sx={{color:'#fff'}}>
                                                View All
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%'}}>
                        {home?.map((homeDate) => (
                            <>
                            {homeDate.section_id == 1 && (
                                <Carousel type="ReadMorePageCarousel" homeDate={homeDate} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                            )}
                            </>
                        ))}
                        </Grid>
                    </Grid>
                </Box>
                {home?.map((homeDate) =>(
                    <>
                        {homeDate.section_id == 4 && (
                            <>
                                {homeDate.data?.map((OurBlog)=>(
                                    <Box 
                                        sx={{
                                            backgroundImage:{
                                                xs: OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                sm: OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                md: OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                lg: OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`
                                            },
                                            position:'relative',
                                            minHeight: {xs:'fit-content',sm:'100vh',md:'456px',lg:'456px'},
                                            height:'100%',
                                            backgroundAttachment:'fixed',
                                            backgroundPosition:'center',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover',
                                            display:'flex',alignItems:'center',justifyContent:'center'
                                        }}>
                                            <Box sx={{position:'absolute',zIndex:1,height:'100%',width:'100%',backgroundColor:'#00000040'}}></Box>
                                            <Box 
                                                sx={{
                                                    position:{xs:'unset',sm:'absolute',md:'absolute',lg:'absolute'},
                                                    zIndex:2,
                                                    width:{xs:'90%',sm:'90%',md:'90%',lg:'90%'},
                                                    height:'auto',
                                                    backgroundColor:'#000000ad',
                                                    borderRadius:'50px',
                                                    p:3,
                                                    display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',
                                                    boxShadow:'rgb(255 255 255) 0 3px 8px',
                                                }}
                                                >
                                                <Typography
                                                        sx={{ textAlign: 'center' }}
                                                        variant="h4"
                                                        dangerouslySetInnerHTML={{ __html: OurBlog.title }} 
                                                />
                                                <Typography
                                                    sx={{ textAlign: 'center' }}
                                                    variant="h4"
                                                    dangerouslySetInnerHTML={{ __html: OurBlog.description }}
                                                />
                                            </Box>
                                    </Box>
                                ))}
                            </>
                        )}
                    </>
                ))}
                <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
            </>
        )
    }else{
        return null ;
    }
}

export default ViewAll;
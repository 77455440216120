import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link } from 'react-router-dom';
import useWindowDimensions from "../../customHook/useWindowDimensions/useWindowDimensions ";
import { suboriginalKey } from './constCarousel';
import Stepper from '@mui/material/Stepper';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
import './Carousel.css';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StarRateIcon from '@mui/icons-material/StarRate';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const limitText = (text, limit) => {
  const words = text.split(' ');
  const trimmed = words.slice(0, limit).join(' ');
  return trimmed + (words.length > limit ? '...' : '');
};

function getCurrentDate(separator=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
return `${year}-${separator}${month<10?`0${month}`:`${month}`}-${separator}${date}`
}
const Carousel = ({ type, banner, getLocation, homeDate, allLocationNavigtion, themeData, substoredEncryptedKey, mainTheme,webLocationLength,alldata}) => {
  const theme = useTheme();
  const { width , height} = useWindowDimensions();
  const windowWidth = width;
  const windowHeight = height - 30;
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = type === 'BannerCarousel' ? banner?.length: type === 'ReadMorePageCarousel' ? homeDate.our_story_all_image?.length : type === 'SlideCarousel' ? homeDate.data?.length: type === 'LocationCarousel' ? homeDate.data?.length:type === 'OurBlogCarousel' ?homeDate.data?.length:type === 'TestimonialsCarousel'? homeDate?.data?.length:homeDate?.data?.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [openOrder, setOpenOrder] = React.useState(false);
  const handleClickOpenOrder = () => {
    setOpenOrder(true);
  };
  const handleCloseOrder = () => {
    setOpenOrder(false);
  };
  let btn_order_now_urls;
  getLocation?.order_now_urls?.map((urls)=>{
    btn_order_now_urls = urls?.url;
  })
  if (substoredEncryptedKey == suboriginalKey) {
    return (
      <React.Fragment>
        {substoredEncryptedKey == suboriginalKey ? (
          <React.Fragment>
            <Box
              className={type === 'BannerCarousel' ? 'banner_main' : type === 'SlideCarousel' ? 'slide_banner_main' : type === 'LocationCarousel' ? '': type === 'TestimonialsCarousel'? '' :type === 'ReadMorePageCarousel'?'readMore_page_main':''}
              sx={{
                position: type === 'LocationCarousel'?'':'relative',
                display: 'flex',
                alignItems: type === 'LocationCarousel'? '': 'center',
                flexDirection: type === 'LocationCarousel'? '': 'column',
                justifyContent: 'center',
                height:type === 'TestimonialsCarousel'?'-webkit-fill-available':'100%',
                marginTop:type === 'TestimonialsCarousel'?'-80px':'auto',
              }}
              id={type === 'BannerCarousel' ? 'banner_main' : type === 'ReadMorePageCarousel'? 'readMore_page_main':''}
            >
              {type === 'BannerCarousel' ? (
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 2,
                    bgcolor: 'background.default',
                    position: 'absolute',
                    zIndex: 1,
                    backgroundColor: 'transparent',
                    width: { xs: '100%', sm: '85%', md: '65%', lg: '50%' },
                    flexDirection: 'column',
                    textAlign:'center'
                  }}
                >
                  {getLocation?.banner_text_status === 'active' ? (
                    <>
                      <Typography sx={{width:'100%',overflow:'hidden'}} dangerouslySetInnerHTML={{ __html: getLocation.banner_text }} />
                      <Typography sx={{width:'100%',overflow:'hidden'}} dangerouslySetInnerHTML={{ __html: getLocation.banner_description }} />
                    </>
                  ) : null}
                </Paper>
              ) : null}
              {type === 'SlideCarousel' ? (
                <Paper
                square
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pl: 2,
                  bgcolor: 'background.default',
                  position:'absolute',
                  top:'60px',
                  zIndex:1,
                  backgroundColor:'transparent',width:{xs:'100%',sm:'85%',md:'65%',lg:'50%'},
                  flexDirection:'column'
                }}
                >
                  { homeDate.action == 'show' && (
                    <Typography dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                  )}
                </Paper>
              ) : null}
              <AutoPlaySwipeableViews
                className={type === 'BannerCarousel' && getLocation?.banner_image_video === 'video' ? 'banner_silder banner_silder_video' : type === 'BannerCarousel' && getLocation?.banner_image_video === 'image'? 'banner_silder' : type === 'SlideCarousel' ? 'slide_banner_silder' :type === 'OurBlogCarousel' ?'OurBlog_main':type === 'ReadMorePageCarousel'? 'readMore_page_slider':''}
                interval={3500}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {(type === 'BannerCarousel' ? banner : type === 'ReadMorePageCarousel' ? homeDate.our_story_all_image : type === 'SlideCarousel' ? homeDate.data: type === 'LocationCarousel' ? homeDate.data:type === 'OurBlogCarousel' ? homeDate.data:type === 'TestimonialsCarousel' ? homeDate.data:homeDate.data)?.map((item, index) => (
                  <div key={index} style={{ width: '100%' ,position:'relative'}}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <>
                        {type === 'BannerCarousel' && (
                          <>
                            {getLocation?.banner_image_video === 'image' && (
                              <div className = {mainTheme.layout == 1 || mainTheme.layout == 5 ? "banner_img1" : 'banner_img'}>
                              <Box
                                sx={{
                                  display: 'block',
                                  overflow: 'hidden',
                                  width: '100%',
                                  // height : "inherit",
                                  height: windowWidth < 600 ? "inherit":  windowHeight+'px',
                                  backgroundImage: {xs:`url(${item.image_medium})`,sm:`url(${item.image_medium})`,md:`url(${item.image})`,lg:`url(${item.image})`},
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              />
                              </div>
                            )}
                            {getLocation?.banner_image_video === 'video' && (
                              <Box
                                component="video"
                                sx={{
                                  display: 'block',
                                  overflow: 'hidden',
                                  width: '100vw',
                                  height: windowWidth < 600 ? "100%":  '100%',
                                  marginTop : windowWidth < 600 ? "50px": '0px',
                                }}
                                autoPlay
                                loop
                                muted
                                poster={`${item.poster_img}`}
                              >
                                <source src={item.image} type="video/mp4" />
                              </Box>
                            )}
                          </>
                        )}
                        {type === 'SlideCarousel' && (
                          <>
                              <Box
                              sx={{
                                  display: 'block',
                                  overflow: 'hidden',
                                  width: '100%',
                                  height:'450px',
                                  backgroundImage:{xs:`url(${item.image_medium})`,sm:`url(${item.image_medium})`,md:`url(${item.image})`,lg:`url(${item.image})`},
                                  backgroundSize:'cover',
                                  backgroundRepeat:'no-repeat',
                                  backgroundPosition:'center',
                                  boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                                }}
                              />
                              <Box 
                                sx={{
                                  position:'absolute',
                                  top:0,
                                  left:0,
                                  right:0,
                                  bottom:0,
                                  display:'flex',
                                  flexDirection:'column',
                                  alignItems:'center',
                                  justifyContent:'center'
                                }}>
                                <Typography 
                                  variant='p'
                                  sx={{textAlign:'center'}}
                                  dangerouslySetInnerHTML={{ __html: item.image_title}}/>
                                  {mainTheme?.layout == 5 && (
                                    <ButtonGroup
                                      disableElevation
                                      variant="contained"
                                      aria-label="Disabled button group"
                                    >
                                      <Button
                                        href={`${item.button_url}`} 
                                        sx={{
                                          my:1.5,
                                          backgroundColor:`${themeData?.button_color}`,
                                          color:`${themeData?.button_font_color}`,
                                          borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                          fontWeight:600,
                                          '&:hover': {
                                            backgroundColor:`${themeData?.button_font_color}`,
                                            color:`${themeData?.button_color}`,
                                            border:`1px solid ${themeData?.button_color}`,
                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                          }
                                        }}
                                      >{item?.button_title}</Button>
                                      <Button href={`${item.button_url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                    </ButtonGroup>
                                  )}
                                  {mainTheme.layout == 1 ? (
                                    <Button 
                                      href={`${item.button_url}`} 
                                      sx={{
                                        my:1.5,
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:'50px',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius:'50px',
                                        }
                                      }}>
                                        {item?.button_title}
                                    </Button>
                                  ) : null}
                                  {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                    <Button 
                                      href={`${item.button_url}`} 
                                      sx={{
                                        my:1.5,
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                          '& .ads-click-icon': {
                                            color: `${themeData?.button_color}`,
                                          }
                                        }
                                      }}>
                                        {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:`${themeData?.button_font_color}`,mx:1,'&:hover': {}}} /> : ''}
                                        {item?.button_title}
                                    </Button>
                                  ) : null}
                              </Box>
                          </>
                        )}
                        {type === 'LocationCarousel' && (
                          <Box sx={{
                            width:{xs:'100%',sm:'75%',md:'100%',lg:'90%',xl:'70%'},
                            textAlign:'center',
                            margin:'auto',
                            height:'100%'
                            }}
                            key={index}>
                            <Card sx={{mx:{xs:1,sm:1,md:2,lg:2,xl:2},p:{xs:1,sm:1,md:2,lg:2,xl:2},height:{xs:'90%',md:'80%',sm:'90%',lg:'80%',xl:'80%'},display:'flex',flexDirection:'column',justifyContent:'center',}}>
                                <Box sx={{color:'#ff7300',my:0.5}}>
                                    <Typography variant="h4" sx={{fontWeight:700}}>{item.title}</Typography>
                                </Box>
                                <Box sx={{color:'#ff7300',my:0.5}}>
                                    <Typography variant="span">{item?.address_line_1}</Typography>
                                </Box>
                                <Box sx={{color:'#ff7300',my:0.5}}>
                                    <Typography variant="span">{item?.address_line_2}</Typography>
                                </Box>
                                <Box sx={{display:'flex',justifyContent:'center',color:'#ff7300',my:0.5}}>
                                    <EmailIcon />
                                    <Typography variant="span" sx={{mx:1}}>{item?.email}</Typography>
                                </Box>
                                <Box sx={{display:'flex',justifyContent:'center',color:'#ff7300',my:0.5}}>
                                    <CallIcon />
                                    <Typography variant="span" sx={{mx:1}}>{item?.phone}</Typography>
                                </Box>
                                <Box sx={{display:mainTheme?.layout == 5 ? 'flow !important' : 'flex' ,justifyContent:'center',my:0.5}}>
                                    <Link to={`/${item?.url}`}>
                                      {mainTheme?.layout == 5 && (
                                        <ButtonGroup
                                          disableElevation
                                          variant="contained"
                                          aria-label="Disabled button group"
                                        >
                                          <Button
                                            sx={{
                                              my:1.5,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              fontWeight:600,
                                              '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              }
                                            }}
                                          >MORE INFO</Button>
                                          <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                        </ButtonGroup>
                                      )}
                                      {mainTheme.layout == 1 ? (
                                        <Button 
                                          sx={{
                                          backgroundColor:`${themeData?.button_color}`,
                                          color:`${themeData?.button_font_color}`,
                                          borderRadius:'50px',
                                          fontWeight:600,
                                          '&:hover': {
                                              backgroundColor:`${themeData?.button_font_color}`,
                                              color:`${themeData?.button_color}`,
                                              border:`1px solid ${themeData?.button_color}`,
                                              borderRadius:'50px',
                                          }
                                          }}>
                                            MORE INFO
                                          </Button>
                                      ) : null}
                                      {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                        <Button 
                                            sx={{
                                            backgroundColor:`${themeData?.button_color}`,
                                            color:`${themeData?.button_font_color}`,
                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                            fontWeight:600,
                                            '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                '& .ads-click-icon': {
                                                  color: `${themeData?.button_color}`,
                                                }
                                            }
                                            }}>
                                              {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:`${themeData?.button_font_color}`,mx:1}} /> : ''}
                                              MORE INFO
                                            </Button>
                                      ) : null}
                                    </Link>
                                    {/* Order Online btn */}
                                    {mainTheme?.layout == 5 && (
                                      <ButtonGroup
                                          disableElevation
                                          variant="contained"
                                          aria-label="Disabled button group"
                                      >
                                          <Button
                                          sx={{
                                              my:1.5,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              fontWeight:600,  marginLeft:"10px",
                                              '&:hover': {
                                              backgroundColor:`${themeData?.button_font_color}`,
                                              color:`${themeData?.button_color}`,
                                              border:`1px solid ${themeData?.button_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              }
                                          }}
                                          href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                          onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}
                                          >
                                            Order Online
                                          </Button>
                                          <Button onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}  href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                      </ButtonGroup>
                                    )}
                                    {mainTheme.layout == 1 ? (
                                      <Button 
                                        sx={{
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:'50px',  marginLeft:"10px",
                                        fontWeight:600,
                                        '&:hover': {
                                            backgroundColor:`${themeData?.button_font_color}`,
                                            color:`${themeData?.button_color}`,
                                            border:`1px solid ${themeData?.button_color}`,
                                            borderRadius:'50px',
                                        }
                                        }} 
                                        href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                        onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                          Order Online
                                      </Button>
                                    ) : null }
                                    {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                      <Button 
                                          sx={{
                                          backgroundColor:`${themeData?.button_color}`,
                                          color:`${themeData?.button_font_color}`,
                                          borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                          marginLeft:"10px",
                                          fontWeight:600,
                                          '&:hover': {
                                              backgroundColor:`${themeData?.button_font_color}`,
                                              color:`${themeData?.button_color}`,
                                              border:`1px solid ${themeData?.button_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              '& .ads-click-icon': {
                                                color: `${themeData?.button_color}`,
                                              }
                                          }
                                          }} 
                                          href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                          onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                                            Order Online
                                      </Button>
                                    ) : null}
                                </Box>
                               
                              <Dialog
                                  open={openOrder}
                                  onClose={handleCloseOrder}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                              >
                                {mainTheme.layout == 1 ? (
                                    <Button sx={{
                                        position:'absolute',
                                        zIndex:10,
                                        right:'5px',
                                        top:'5px',
                                        borderRadius:'5px',
                                        padding:0,
                                        margin:0,
                                        maxWidth:'30px',
                                        minWidth:'30px',
                                        width:'30px',
                                        height:'30px',
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'>
                                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                    </Button>
                                ) : null}
                                {mainTheme.layout == 2 ? (
                                  <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'50%',
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      padding:0,
                                      margin:0,
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                  }} 
                                  onClick={handleCloseOrder}
                                  color='error' 
                                  variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                                ) : null}
                                  {mainTheme.layout == 3 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'5px',
                                      padding:0,
                                      margin:0,
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                  }} 
                                  onClick={handleCloseOrder}
                                  color='error' 
                                  variant='contained'>
                                      <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                  </Button>
                                  ) : null}
                                  {mainTheme.layout == 4 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'50%',
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      padding:0,
                                      margin:0,
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                                  ) : null}
                                  {mainTheme.layout == 5 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'5px',
                                      padding:0,
                                      margin:0,
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'>
                                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                    </Button>
                                  ) : null}
                                  <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',borderBottom:'0.6px solid #eee'}}>
                                    <FmdGoodTwoToneIcon sx={{width:'100px',height:'auto',fill:'red',stroke:'white'}}/>
                                    <Typography sx={{fontWeight:700}} variant='h5'>Choose Location</Typography>
                                    <Typography variant='p'>Powered by Eatstations</Typography>
                                  </DialogTitle>
                                  <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {alldata && alldata.order_now_urls?.map((order_now_urls)=>(
                                      <Box sx={{textAlign:'center',borderBottom:'0.4px solid #eee',py:2}}>
                                        <Box sx={{display:'flex',alignItems:'center',m:1}}>
                                          <Box sx={{backgroundColor:'#fdc51680',width:'50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',mx:1}}>
                                            <FmdGoodTwoToneIcon sx={{fill:'white',stroke:'black'}}/>
                                          </Box>
                                          <Typography sx={{fontWeight:700,color:'black'}} variant='h5'>{order_now_urls?.title}</Typography>
                                        </Box>
                                        <address sx={{m:1}}>{order_now_urls?.address}</address>
                                        {mainTheme?.layout == 5 && (
                                          <ButtonGroup
                                              disableElevation
                                              variant="contained"
                                              aria-label="Disabled button group"
                                          >
                                              <Button
                                                sx={{
                                                    my:1.5,
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                    backgroundColor:`${themeData?.button_font_color}`,
                                                    color:`${themeData?.button_color}`,
                                                    border:`1px solid ${themeData?.button_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    }
                                                }}
                                                href={`${order_now_urls?.url}`}
                                              >
                                                Order Now
                                              </Button>
                                              <Button href={`${order_now_urls?.url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                          </ButtonGroup>
                                        )}
                                        {mainTheme.layout == 1 ? (
                                          <Button 
                                            sx={{
                                              my:2,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:'50px',
                                              fontWeight:600,
                                              '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:'50px',
                                              }
                                            }} 
                                            href={`${order_now_urls?.url}`}
                                          >
                                            Order Now</Button>
                                        ) : null}
                                        {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                          <Button 
                                            sx={{
                                              my:2,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              fontWeight:600,
                                              '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                '& .ads-click-icon': {
                                                  color: `${themeData?.button_color}`,
                                                }
                                              }
                                            }} 
                                            href={`${order_now_urls?.url}`}
                                          >
                                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                                            Order Now</Button>
                                        ) : null}
                                      </Box>
                                    ))}
                                  </DialogContentText>
                                  </DialogContent>
                              </Dialog>
                            </Card>
                        </Box>
                        
                        )}
                        {type === 'OurBlogCarousel' && (
                          <Box
                            sx={{height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}}}>
                            <Grid container
                                sx={{height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}}}>
                                <Grid item xs={12} md={6}>
                                    <Box
                                        sx={{
                                            height:{xs:'auto',sm:'400px',md:'480px',lg:'480px'},
                                            display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            padding:'50px',
                                        }}>
                                        <Box sx={{my:1}}>
                                            <Typography 
                                                sx={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
                                                variant="h4" 
                                                dangerouslySetInnerHTML={{ __html: item.title }} />
                                            <Typography variant="p">{getCurrentDate()}</Typography>
                                        </Box>
                                        <Box sx={{my:1}}>
                                            <Typography
                                                sx={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
                                                variant="h4" 
                                                dangerouslySetInnerHTML={{ __html: limitText(item.description, 30) }} />
                                        </Box>
                                        <Box sx={{my:1}}>
                                            <Link to={`/${allLocationNavigtion}/detail/${item.id}`}>
                                              {mainTheme.layout && mainTheme?.layout == 5 && (
                                                <ButtonGroup
                                                  disableElevation
                                                  variant="contained"
                                                  aria-label="Disabled button group"
                                                >
                                                  <Button
                                                    sx={{
                                                      my:1.5,
                                                      backgroundColor:`${themeData?.button_color}`,
                                                      color:`${themeData?.button_font_color}`,
                                                      borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                      fontWeight:600,
                                                      '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                      }
                                                    }}
                                                  >READ MORE</Button>
                                                  <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                </ButtonGroup>
                                              )}
                                              {mainTheme?.layout == 1 ? (
                                                <Button 
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    READ MORE
                                                </Button>
                                              ) : null}
                                              {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                          color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:`${themeData?.button_font_color}`,mx:1}} /> : ''}
                                                    READ MORE
                                                </Button>
                                              ) : null}
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box
                                        sx={{
                                            backgroundImage: {
                                                xs:item.blog_image?.length > 0 && item.blog_image[0] ? `url(${item.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                sm:item.blog_image?.length > 0 && item.blog_image[0] ? `url(${item.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                md:item.blog_image?.length > 0 && item.blog_image[0] ? `url(${item.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                                lg:item.blog_image?.length > 0 && item.blog_image[0] ? `url(${item.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`
                                            },
                                            backgroundPosition:'center',
                                            backgroundSize:'cover',
                                            backgroundRepeat:'no-repeat',
                                            width:'100%',
                                            height:{xs:'300px',sm:'300px',md:'480px',lg:'480px'}
                                    }}/>
                                </Grid>
                            </Grid>
                          </Box>
                        )}
                        {type === 'TestimonialsCarousel' && (
                          <Box
                              sx={{
                              display: 'flex',
                              overflow: 'hidden',
                              width: {xs:'100%',sm:'80%',md:'60%',lg:'50%'},
                              margin:'auto',
                              flexDirection:'column',
                              justifyContent:'center',
                              alignItems:'center',
                              }}
                          >
                              <Typography variant="p" className='testimonial_des' sx={{textAlign:'center',width: '100%'}} dangerouslySetInnerHTML={{__html:item.message}} />
                              <Typography variant="p" sx={{textAlign:'center',width: '100%'}} dangerouslySetInnerHTML={{__html:item.name}} />
                              <div id="rating0" class="1">
                                <StarRateIcon sx={{color:`${item.rating >= 1 ? getLocation?.testimonial_star_color : '#9E9E9E'}`,fontSize:"25px"}} />
                                <StarRateIcon sx={{color:`${item.rating >= 2 ? getLocation?.testimonial_star_color : '#9E9E9E'}`,fontSize:"25px"}} />
                                <StarRateIcon sx={{color:`${item.rating >= 3 ? getLocation?.testimonial_star_color : '#9E9E9E'}`,fontSize:"25px"}} />
                                <StarRateIcon sx={{color:`${item.rating >= 4 ? getLocation?.testimonial_star_color : '#9E9E9E'}`,fontSize:"25px"}} />
                                <StarRateIcon sx={{color:`${item.rating == 5 ? getLocation?.testimonial_star_color : '#9E9E9E'}`,fontSize:"25px"}} />
                                {/* <img class="rating_star" src={"/star.png"}/>
                                <img class="rating_star" id="7" src={"/star.png"} />
                                <img class="rating_star" id="8" src={"/star.png"} />
                                <img class="rating_star" id="9" src={"/star.png"} />
                                <img class="rating_star" id="10" src={"/star.png"} /> */}
                              </div>
                          </Box>
                        )}
                        {type === 'ReadMorePageCarousel' && (
                           <>                   
                              <Box
                                sx={{
                                  display: 'block',
                                  overflow: 'hidden',
                                  width: '100%',
                                  height: { xs: '600px', sm: '450px', md: '450px', lg: '450px' },
                                  backgroundImage: `url(${item.image})`,
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              />                              
                            </>
                        )}
                      </>
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              {maxSteps > 1 ? (
                <>
                {mainTheme?.layout == 1 ? (
                  <MobileStepper
                    className='banner_dots_theme1'
                    variant="progress"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {windowWidth < 600 ? (
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <ArrowCircleRightIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                            )}
                          </>
                        ) : (
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                          </>
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {windowWidth < 600 ? (
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <ArrowCircleLeftIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                            )}
                          </>
                        ):(
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                          </>
                        )}
                      </Button>
                    }
                  />
                  
                ) : null}
                {mainTheme?.layout == 2 ? (
                  <MobileStepper
                    className={type === 'BannerCarousel' ? 'banner_dots' : type === 'SlideCarousel' ? 'slide_banner_dots' : type === 'LocationCarousel' ? 'location_dots': type === 'OurBlogCarousel' ?'OurBlog_dots': type === 'TestimonialsCarousel'? 'testimonials_dots':type === 'ReadMorePageCarousel'?'readMore_page_dots':''}
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <ArrowCircleRightIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            Next
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </>
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <ArrowCircleLeftIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </>
                        )}
                      </Button>
                    }
                  />
                ) : null}
                {mainTheme?.layout == 3 ? (
                  <MobileStepper
                    className='banner_dots_theme3'
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <ArrowCircleRightIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            Next
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </>
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <ArrowCircleLeftIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </>
                        )}
                      </Button>
                    }
                  />
                ) : null}
                {mainTheme?.layout == 4 ? (
                  <MobileStepper
                    className='banner_dots_theme4'
                    variant="progress"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {windowWidth < 600 ? (
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <ArrowCircleRightIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                            )}
                          </>
                        ) : (
                          <>
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                          </>
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {windowWidth < 600 ? (
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <ArrowCircleLeftIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                            )}
                          </>
                        ):(
                          <>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                          </>
                        )}
                      </Button>
                    }
                  />
                ) : null}
                {mainTheme?.layout == 5 ? (
                  <MobileStepper
                    className={'banner_dots_theme5'}
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <ArrowCircleRightIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            Next
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </>
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {windowWidth < 600 ? (
                          theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <ArrowCircleLeftIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                          )
                        ) : (
                          <>
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </>
                        )}
                      </Button>
                    }
                  />
                ) : null}
                </>
              ) : null}
            </Box>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }else{
    return null;
  }
};

export default Carousel;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    getreslistData1: [],
    webmenuitemData1: null,
    webmenuitemDataSlotDefault: null,
    webmenuitemDataSlot11: null,
    webmenuitemDataSlot21: null,
    webmenuitemDataSlot31: null,
    loading1: true,
    error: null,
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    fetchDataStart(state) {
      state.loading1 = true;
      state.error = null;
    },
    fetchDataSuccess(state, action) {
      state.loading1 = false;
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
    },
    fetchDataFailure(state, action) {
      state.loading1 = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = apiSlice.actions;

export default apiSlice.reducer;

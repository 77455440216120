import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import axios from 'axios';
import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
import ButtonGroup from '@mui/material/ButtonGroup';
const Reservation = ({homeDate, themeData, alldata, getreslistData, getLocation, mainLocation, substoredEncryptedKey, mainTheme})=> {
    const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
    const [openJoinWaiting, setOpenJoinWaiting] = React.useState(false);
    const [data, setData] = React.useState({
        name: '',
        phone: '',
        no_of_people: '',
        res_id: '',
        res_code: '',
        country_code: 'US',
        phone_code: 1,
    });
    const reservationOptions = getreslistData;
    // const [reservationOptions, setReservationOptions] = React.useState([]);
    const [errors, setErrors] = React.useState({});

    const handleClickOpenJoinWaiting = () => {
        setOpenJoinWaiting(true);
    };
    const handleCloseJoinWaiting = () => {
        setOpenJoinWaiting(false);
    };

    // useEffect(() => {
    //     const fetchLocationData = async () => {
    //         try {
    //             if (mainLocation !== '' && mainTheme) {
    //                 const response = await axios.post('https://www.thaispicekc.com/es-admin/api/get_headermenu', {
    //                     location_id: mainLocation,
    //                     theme_id: mainTheme.layout
    //                 });
    //                 setData((prevState) => ({
    //                     ...prevState,
    //                     res_id: response.data.get_location?.eatstations_res_id,
    //                     res_code: response.data.get_location?.eatstations_res_code,
    //                 }));
    //             }
    //         } catch (error) {
    //             console.error('Error fetching location data:', error);
    //         }
    //     };
    //     fetchLocationData();
    // }, [mainLocation]);

    const res_id = getLocation.eatstations_res_id;
    // useEffect(() => {
    //     const fetchReservationOptions = async () => {
    //         try {
    //             if (res_id !== '' && res_id) {
    //                 const response = await axios.get(`https://www.eatstations.com/api/get_res_list?res_id=${res_id}`);
    //                 if (response.data) {
    //                     setReservationOptions(response.data);
    //                 } else {
    //                     setReservationOptions([]);
    //                 }
    //             }
    //         } catch (error) {
    //             console.error('Error fetching reservation options:', error);
    //             setReservationOptions([]);
    //         }
    //     };
    //     fetchReservationOptions();
    // }, [res_id]);

    const formatPhoneNumber = (value) => {
        const digits = value.replace(/\D/g, '');
        let formattedValue = '';
        for (let i = 0; i < digits?.length; i += 3) {
          if (i + 3 < digits?.length && i < 6) {
            formattedValue += digits.slice(i, i + 3) + '-';
          } else {
            formattedValue += digits.slice(i);
            break;
          }
        }
        return formattedValue;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === 'phone' ? formatPhoneNumber(value) : value;
        setData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        if (name === 'name' && !value) {
            error = 'Name is required';
        }
        if (name === 'phone' && (!/^\d{3}-\d{3}-\d{4}$/.test(value))) {
            error = 'Phone number must be in the format 656-565-6565';
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };
    const [loadingSubmit , setLoadingSubmit] = React.useState(false);
    const [successMessage , setSuccessMessage] = React.useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        const formValid = Object.keys(errors).every((key) => errors[key] === '') && data.name && data.phone;
        if (!formValid) {
            alert('Please fill out the form correctly.');
            setLoadingSubmit(false);
            return;
        }

        try {
            const response = await axios.post('https://www.eatstations.com/api/waiting_list_store', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Success:', response.data);
            handleCloseJoinWaiting();
            setLoadingSubmit(false);
            setSuccessMessage(true);
            setTimeout(() => {
                setSuccessMessage(false);
                handleCloseJoinWaiting();
            }, 2000);
        } catch (error) {
            console.error('Error:', error.response.data);
        }
    };

    const backgroundStyle = homeDate.data.is_page_bg === 1
        ? { backgroundColor: homeDate.data.page_bg_color }
        : {
            backgroundImage: {
                xs: `url(${homeDate.data.image_medium})`,
                sm: `url(${homeDate.data.image_medium})`,
                md: `url(${homeDate.data.image})`,
                lg: `url(${homeDate.data.image})`
            }
        };
    const reservationOptionsArray = [];
    //alert(reservationOptions.reservation_no_of_people);
    const reservationOptionsAll = reservationOptions.reservation_no_of_people;
    if (reservationOptionsAll != 0) {
        for (let i = 1; i <= reservationOptionsAll; i++) {
          reservationOptionsArray.push({
            reservation_no_of_people: i,
          });
        }
    }else{
        for (let i = 1; i <= 5; i++) {
          reservationOptionsArray.push({
            reservation_no_of_people: i,
          });
        }
    }
    if (substoredEncryptedKey == suboriginalKey) {
        return (
            <React.Fragment>
                {substoredEncryptedKey == suboriginalKey && (
                    <React.Fragment>
                        <Box sx={{position:'relative'}}>
                            <Box id='Reservation' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
                            <Box
                                sx={{
                                    height:'480px',
                                    ...backgroundStyle,
                                    backgroundPosition:'center',
                                    backgroundSize:'cover',
                                    backgroundRepeat:'no-repeat',
                                    boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                }}
                                >
                                { homeDate.action == 'show' && (
                                    <Box sx={{my:1}}>
                                        <Typography 
                                            sx={{textAlign:'center'}}
                                            variant="h4" 
                                            dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                                    </Box>
                                )}
                                <Box sx={{my:1}}>
                                    <Typography 
                                        sx={{textAlign:'center'}}
                                        variant="p" 
                                        dangerouslySetInnerHTML={{ __html: homeDate.data.description }} />
                                </Box>
                                <Box sx={{my:1}}>
                                    {homeDate.data.res_url_status === 'active' &&(
                                        <>
                                            {mainTheme?.layout == 5 && (
                                                <ButtonGroup
                                                    disableElevation
                                                    variant="contained"
                                                    aria-label="Disabled button group"
                                                >
                                                    <Button
                                                        sx={{
                                                            my:1.5,
                                                            backgroundColor:`${themeData?.button_color}`,
                                                            color:`${themeData?.button_font_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            fontWeight:600,
                                                            '&:hover': {
                                                            backgroundColor:`${themeData?.button_font_color}`,
                                                            color:`${themeData?.button_color}`,
                                                            border:`1px solid ${themeData?.button_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            }
                                                        }}
                                                        href={`${homeDate?.data?.url}`}
                                                    >
                                                        Reservations
                                                    </Button>
                                                    <Button href={`${homeDate?.data?.url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    Reservations
                                                </Button>
                                            ) : null }
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    href={`${homeDate?.data?.url}`}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                           color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    Reservations
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                    {homeDate.data.waiting_list_status === 'active' && res_id !== '' && res_id &&(
                                        <>
                                            {mainTheme?.layout == 5 && (
                                                <ButtonGroup
                                                    disableElevation
                                                    variant="contained"
                                                    aria-label="Disabled button group"
                                                >
                                                    <Button
                                                        sx={{
                                                            my:1.5,
                                                            backgroundColor:`${themeData?.button_color}`,
                                                            color:`${themeData?.button_font_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            fontWeight:600,
                                                            '&:hover': {
                                                            backgroundColor:`${themeData?.button_font_color}`,
                                                            color:`${themeData?.button_color}`,
                                                            border:`1px solid ${themeData?.button_color}`,
                                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                            }
                                                        }}
                                                        onClick={handleClickOpenJoinWaiting}
                                                    >
                                                        Join Waiting List
                                                    </Button>
                                                    <Button onClick={handleClickOpenJoinWaiting} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                                </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button 
                                                    onClick={handleClickOpenJoinWaiting}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    Join Waiting List
                                                </Button>
                                            ) : null}
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    onClick={handleClickOpenJoinWaiting}
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                           color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    Join Waiting List
                                                </Button>
                                            ) : null }
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Dialog
                            open={openJoinWaiting}
                            onClose={handleCloseJoinWaiting}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {mainTheme.layout == 1 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleCloseJoinWaiting}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                            {mainTheme.layout == 2 ? (
                                <Button 
                                    sx={{
                                        position:'absolute',
                                        zIndex:10,
                                        right:'5px',
                                        top:'5px',
                                        borderRadius:'50%',
                                        maxWidth:'30px',
                                        minWidth:'30px',
                                        padding:0,
                                        margin:0,
                                        width:'30px',
                                        height:'30px',
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseJoinWaiting}
                                    color='error' 
                                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                            ) : null}
                            {mainTheme.layout == 3 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleCloseJoinWaiting}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                            {mainTheme.layout == 4 ? (
                                <Button 
                                sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'50%',
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    padding:0,
                                    margin:0,
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleCloseJoinWaiting}
                                color='error' 
                                variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                            ) : null}
                            {mainTheme.layout == 5 ? (
                                <Button sx={{
                                    position:'absolute',
                                    zIndex:10,
                                    right:'5px',
                                    top:'5px',
                                    borderRadius:'5px',
                                    padding:0,
                                    margin:0,
                                    maxWidth:'30px',
                                    minWidth:'30px',
                                    width:'30px',
                                    height:'30px',
                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                }} 
                                onClick={handleCloseJoinWaiting}
                                color='error' 
                                variant='contained'>
                                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                </Button>
                            ) : null}
                            <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',borderBottom:'0.6px solid #eee'}}>
                                <Typography sx={{fontWeight:700}} variant='h5'>Join Waiting list</Typography>
                                <Typography variant='body1'>Fill the form and add to waiting for your party!</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{my:2}}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Enter Name"
                                                name="name"
                                                value={data.name}
                                                onChange={handleChange}
                                                required
                                                error={!!errors.name}
                                                helperText={errors.name}
                                                onMouseUp={() => validateField('name', data.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Enter Contact Number"
                                                name="phone"
                                                value={data.phone}
                                                onChange={handleChange}
                                                required
                                                error={!!errors.phone}
                                                helperText={errors.phone}
                                                onMouseUp={() => validateField('phone', data.phone)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="noofpeople-select-label">No of people</InputLabel>
                                                <Select
                                                    labelId="noofpeople-select-label"
                                                    id="noofpeople-select"
                                                    name="no_of_people"
                                                    value={data.no_of_people}
                                                    label="No of people"
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    {reservationOptionsArray.map((reservationOptionsArray)=>(
                                                        <MenuItem
                                                        key={reservationOptions.id}
                                                        value={reservationOptionsArray.reservation_no_of_people}
                                                        onClick={() => {
                                                            setData((prevState) => ({
                                                            ...prevState,
                                                            res_id: reservationOptions.id,
                                                            res_code: reservationOptions.code,
                                                            }));
                                                        }}
                                                        >
                                                            {reservationOptionsArray.reservation_no_of_people}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sx={{textAlign:'center'}}>
                                            <Button
                                                type="submit"
                                                sx={{
                                                    px:3,
                                                    backgroundColor:loadingSubmit == true ? 'gray' :`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    mx:1,
                                                    '&:hover': {
                                                        backgroundColor:loadingSubmit == true ? 'gray' :`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:loadingSubmit == true ? '1px solid gray' :`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    }
                                                }}
                                                >
                                                    {loadingSubmit == true ? <CircularProgress size={24} color="success" /> :'Submit'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </DialogContent>
                        </Dialog>
                        {successMessage == true && (
                            <Box sx={{position:'fixed',top:0,bottom:0,left:0,right:0,zIndex:15,margin:'auto',display:"flex",alignItems:'center',justifyContent:'center',backgroundColor:'#0009'}}>
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                    Join Waiting list data submited successful.
                                </Alert>
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }else{
        return null;
    }
}
export default Reservation;
import React from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Carousel from "../carousel/Carousel";
import { suboriginalKey } from "./constourBlog";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
const limitText = (text, limit) => {
    const words = text.split(' ');
    const trimmed = words.slice(0, limit).join(' ');
    return trimmed + (words?.length > limit ? '...' : '');
};
function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
return `${year}-${separator}${month<10?`0${month}`:`${month}`}-${separator}${date}`
}
const OurBlog = ({ homeDate, allLocationNavigtion, themeData, substoredEncryptedKey,mainTheme}) => {
    const OurBlogSingle = (
        <>
            {homeDate.data?.map(OurBlog =>(
                <Box
                    sx={{height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}}}>
                    <Grid container
                        sx={{height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}}}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    height:{xs:'auto',sm:'400px',md:'480px',lg:'480px'},
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    padding:'50px',
                                }}>
                                <Box sx={{my:1}}>
                                    <Typography 
                                        sx={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
                                        variant="h4" 
                                        dangerouslySetInnerHTML={{ __html: OurBlog?.title }} />
                                    <Typography variant="p">{getCurrentDate()}</Typography>
                                </Box>
                                <Box sx={{my:1}}>
                                    <Typography
                                        sx={{textAlign:'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
                                        variant="h4" 
                                        dangerouslySetInnerHTML={{ __html: limitText(OurBlog?.description, 30) }} />
                                </Box>
                                <Box sx={{my:1}}>
                                    <Link to={`/${allLocationNavigtion}/detail/${OurBlog?.id}`}>
                                        {mainTheme?.layout == 5 && (
                                            <ButtonGroup
                                                disableElevation
                                                variant="contained"
                                                aria-label="Disabled button group"
                                            >
                                                <Button
                                                    sx={{
                                                        my:1.5,
                                                        backgroundColor:`${themeData?.button_color}`,
                                                        color:`${themeData?.button_font_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        fontWeight:600,
                                                        '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        }
                                                    }}
                                                >
                                                    READ MORE
                                                </Button>
                                                <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                            </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button 
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    READ MORE
                                                </Button>
                                            ) : null}
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button 
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                            color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    READ MORE
                                                </Button>
                                            ) : null}
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    backgroundImage: {
                                        xs:OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                        sm:OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image_medium})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                        md:OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`,
                                        lg:OurBlog.blog_image?.length > 0 && OurBlog.blog_image[0] ? `url(${OurBlog.blog_image[0].image})`:`url(${process.env.PUBLIC_URL + '/d-image.png'})`
                                    },
                                    backgroundPosition:'center',
                                    backgroundSize:'cover',
                                    backgroundRepeat:'no-repeat',
                                    width:'100%',
                                    height:{xs:'300px',sm:'300px',md:'480px',lg:'480px'}
                            }}/>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </>
    );
    const OurBlogMultipal = (
        <Card sx={{width:'90%',margin:'auto'}}>
            <Carousel type="OurBlogCarousel" homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
        </Card>
    );
    if (suboriginalKey == substoredEncryptedKey) {
        return (
            <React.Fragment>
                {substoredEncryptedKey == suboriginalKey && (
                    <React.Fragment>
                        <Box sx={{position:'relative'}}>
                            <Box id='Our_blog' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
                            {homeDate?.data?.length != 1 ? OurBlogMultipal : OurBlogSingle }
                            {homeDate?.data?.length != 1 && (
                                <Box sx={{py:2,textAlign:'end',width:'90%',margin:'auto'}}>
                                    <Link to={`/${allLocationNavigtion}/viewall`}>
                                        {mainTheme?.layout == 5 && (
                                            <ButtonGroup
                                                disableElevation
                                                variant="contained"
                                                aria-label="Disabled button group"
                                            >
                                                <Button
                                                    sx={{
                                                        my:1.5,
                                                        backgroundColor:`${themeData?.button_color}`,
                                                        color:`${themeData?.button_font_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        fontWeight:600,
                                                        '&:hover': {
                                                        backgroundColor:`${themeData?.button_font_color}`,
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        }
                                                    }}
                                                >
                                                    view All
                                                </Button>
                                                <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                            </ButtonGroup>
                                            )}
                                            {mainTheme.layout == 1 ? (
                                                <Button
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:'50px',
                                                    fontWeight:600,
                                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px,rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:'50px',
                                                    }
                                                    }}>
                                                    view All
                                                </Button>
                                            ) : null}
                                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                                <Button
                                                    sx={{
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px,rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                                    '&:hover': {
                                                        backgroundColor:'transparent',
                                                        color:`${themeData?.button_color}`,
                                                        border:`1px solid ${themeData?.button_color}`,
                                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                        '& .ads-click-icon': {
                                                            color: `${themeData?.button_color}`,
                                                        }
                                                    }
                                                    }}>
                                                    {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                                    view All
                                                </Button>
                                            ) : null}
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }else{
        return null;
    }
};

export default OurBlog;

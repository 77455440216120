import { configureStore } from '@reduxjs/toolkit';
import apiSlice from '../features/API/apiSlice';
import headerSlice from '../features/header/headerSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../middleware/sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    api: apiSlice,
    header: headerSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import Carousel from '../carousel/Carousel';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { suboriginalKey } from './constLocation';
import ButtonGroup from '@mui/material/ButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';

function Location({homeDate,themeData,substoredEncryptedKey,mainTheme,webLocationLength,alldata}) {
    const [openOrder, setOpenOrder] = React.useState(false);
    const handleClickOpenOrder = () => {
      setOpenOrder(true);
    };
    const handleCloseOrder = () => {
      setOpenOrder(false);
    };
    const maxSteps = homeDate?.data?.length;
    const { height, width } = useWindowDimensions();
    const windowWidth = width;
    const locationData = (
        <>
            {homeDate.data?.map((locations,index) => (
                <Grid item md={6} lg={6} sx={{height:'100%'}}>
                    <Box sx={{
                        width:{xs:'100%',sm:'75%',md:'100%',lg:'90%',xl:'70%'},
                        textAlign:'center',
                        margin:'auto',
                        height:'100%'
                        }}
                        key={index}>
                        <Card sx={{mx:{xs:1,sm:1,md:2,lg:2,xl:2},p:{xs:1,sm:1,md:2,lg:2,xl:2},height:{xs:'90%',md:'80%',sm:'90%',lg:'80%',xl:'80%'},display:'flex',flexDirection:'column',justifyContent:'center',}}>
                        { homeDate.action == 'show' && (
                            <Box sx={{color:'#ff7300',my:0.5}}>
                                <Typography variant="h4" sx={{fontWeight:700}}>{locations.title}</Typography>
                            </Box>
                        )}
                            <Box sx={{color:'#ff7300',my:0.5}}>
                                <Typography variant="span">{locations?.address_line_1}</Typography>
                            </Box>
                            <Box sx={{color:'#ff7300',my:0.5}}>
                                <Typography variant="span">{locations?.address_line_2}</Typography>
                            </Box>
                            <Box sx={{display:'flex',justifyContent:'center',color:'#ff7300',my:0.5}}>
                                <EmailIcon />
                                <Typography variant="span" sx={{mx:1}}>{locations?.email}</Typography>
                            </Box>
                            <Box sx={{display:'flex',justifyContent:'center',color:'#ff7300',my:0.5}}>
                                <CallIcon />
                                <Typography variant="span" sx={{mx:1}}>{locations?.phone}</Typography>
                            </Box>
                            <Box sx={{display:'flex',justifyContent:'center',my:0.5}}>
                                <a href={`/${locations?.url}`}>
                                    {mainTheme?.layout == 5 && (
                                        <ButtonGroup
                                            disableElevation
                                            variant="contained"
                                            aria-label="Disabled button group"
                                        >
                                            <Button
                                            sx={{
                                                my:1.5,
                                                backgroundColor:`${themeData?.button_color}`,
                                                color:`${themeData?.button_font_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                fontWeight:600,
                                                '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                }
                                            }}
                                            >MORE INFO</Button>
                                            <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                        </ButtonGroup>
                                    )}
                                    {mainTheme.layout == 1 ? (
                                        <Button 
                                            sx={{
                                                backgroundColor:`${themeData?.button_color}`,
                                                color:`${themeData?.button_font_color}`,
                                                borderRadius:'50px',
                                                fontWeight:600,
                                                '&:hover': {
                                                    backgroundColor:`${themeData?.button_font_color}`,
                                                    color:`${themeData?.button_color}`,
                                                    border:`1px solid ${themeData?.button_color}`,
                                                    borderRadius:'50px',
                                            }
                                            }}
                                        >
                                            MORE INFO
                                        </Button>
                                    ) : null}
                                    {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                        <Button 
                                            sx={{
                                                backgroundColor:`${themeData?.button_color}`,
                                                color:`${themeData?.button_font_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                fontWeight:600,
                                                '&:hover': {
                                                    backgroundColor:`${themeData?.button_font_color}`,
                                                    color:`${themeData?.button_color}`,
                                                    border:`1px solid ${themeData?.button_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    '& .ads-click-icon': {
                                                        color: `${themeData?.button_color}`,
                                                    }
                                                }
                                            }}
                                        >
                                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                                            MORE INFO
                                        </Button>
                                    ) : null}
                                </a>
                                {/* Order Online btn */}
                                {mainTheme?.layout == 5 && (
                                      <ButtonGroup
                                          disableElevation
                                          variant="contained"
                                          aria-label="Disabled button group"
                                      >
                                          <Button
                                          sx={{
                                              my:1.5,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              fontWeight:600,  marginLeft:"10px",
                                              '&:hover': {
                                              backgroundColor:`${themeData?.button_font_color}`,
                                              color:`${themeData?.button_color}`,
                                              border:`1px solid ${themeData?.button_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              }
                                          }}
                                          onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}
                                          >
                                            Order Online
                                          </Button>
                                          <Button onClick={webLocationLength > 1 ? handleClickOpenOrder : ''} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                      </ButtonGroup>
                                )}
                                {mainTheme.layout == 1 ? (
                                <Button 
                                    sx={{
                                    backgroundColor:`${themeData?.button_color}`,
                                    color:`${themeData?.button_font_color}`,
                                    borderRadius:'50px',  marginLeft:"10px",
                                    fontWeight:600,
                                    '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius:'50px',
                                    }
                                    }} 
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                    Order Online
                                </Button>
                                ) : null }
                                {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                <Button 
                                    sx={{
                                    backgroundColor:`${themeData?.button_color}`,
                                    color:`${themeData?.button_font_color}`,
                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                    marginLeft:"10px",
                                    fontWeight:600,
                                    '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        '& .ads-click-icon': {
                                            color: `${themeData?.button_color}`,
                                        }
                                    }
                                    }} 
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                        {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                                        Order Online
                                </Button>
                                ) : null}
                                <Dialog
                                  open={openOrder}
                                  onClose={handleCloseOrder}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                              >
                                {mainTheme.layout == 1 ? (
                                    <Button sx={{
                                        position:'absolute',
                                        zIndex:10,
                                        right:'5px',
                                        top:'5px',
                                        borderRadius:'5px',
                                        padding:0,
                                        margin:0,
                                        maxWidth:'30px',
                                        minWidth:'30px',
                                        width:'30px',
                                        height:'30px',
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'>
                                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                    </Button>
                                ) : null}
                                {mainTheme.layout == 2 ? (
                                  <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'50%',
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      padding:0,
                                      margin:0,
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                  }} 
                                  onClick={handleCloseOrder}
                                  color='error' 
                                  variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                                ) : null}
                                  {mainTheme.layout == 3 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'5px',
                                      padding:0,
                                      margin:0,
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                  }} 
                                  onClick={handleCloseOrder}
                                  color='error' 
                                  variant='contained'>
                                      <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                  </Button>
                                  ) : null}
                                  {mainTheme.layout == 4 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'50%',
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      padding:0,
                                      margin:0,
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                                  ) : null}
                                  {mainTheme.layout == 5 ? (
                                    <Button sx={{
                                      position:'absolute',
                                      zIndex:10,
                                      right:'5px',
                                      top:'5px',
                                      borderRadius:'5px',
                                      padding:0,
                                      margin:0,
                                      maxWidth:'30px',
                                      minWidth:'30px',
                                      width:'30px',
                                      height:'30px',
                                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                    }} 
                                    onClick={handleCloseOrder}
                                    color='error' 
                                    variant='contained'>
                                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                                    </Button>
                                  ) : null}
                                  <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',borderBottom:'0.6px solid #eee'}}>
                                    <FmdGoodTwoToneIcon sx={{width:'100px',height:'auto',fill:'red',stroke:'white'}}/>
                                    <Typography sx={{fontWeight:700}} variant='h5'>Choose Location</Typography>
                                    <Typography variant='p'>Powered by Eatstations</Typography>
                                  </DialogTitle>
                                  <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    {alldata && alldata.order_now_urls?.map((order_now_urls)=>(
                                      <Box sx={{textAlign:'center',borderBottom:'0.4px solid #eee',py:2}}>
                                        <Box sx={{display:'flex',alignItems:'center',m:1}}>
                                          <Box sx={{backgroundColor:'#fdc51680',width:'50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',mx:1}}>
                                            <FmdGoodTwoToneIcon sx={{fill:'white',stroke:'black'}}/>
                                          </Box>
                                          <Typography sx={{fontWeight:700,color:'black'}} variant='h5'>{order_now_urls?.title}</Typography>
                                        </Box>
                                        <address sx={{m:1}}>{order_now_urls?.address}</address>
                                        {mainTheme?.layout == 5 && (
                                          <ButtonGroup
                                              disableElevation
                                              variant="contained"
                                              aria-label="Disabled button group"
                                          >
                                              <Button
                                                sx={{
                                                    my:1.5,
                                                    backgroundColor:`${themeData?.button_color}`,
                                                    color:`${themeData?.button_font_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    fontWeight:600,
                                                    '&:hover': {
                                                    backgroundColor:`${themeData?.button_font_color}`,
                                                    color:`${themeData?.button_color}`,
                                                    border:`1px solid ${themeData?.button_color}`,
                                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                    }
                                                }}
                                                href={`${order_now_urls?.url}`}
                                              >
                                                Order Now
                                              </Button>
                                              <Button href={`${order_now_urls?.url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                          </ButtonGroup>
                                        )}
                                        {mainTheme.layout == 1 ? (
                                          <Button 
                                            sx={{
                                              my:2,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:'50px',
                                              fontWeight:600,
                                              '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:'50px',
                                              }
                                            }} 
                                            href={`${order_now_urls?.url}`}
                                          >
                                            Order Now</Button>
                                        ) : null}
                                        {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                          <Button 
                                            sx={{
                                              my:2,
                                              backgroundColor:`${themeData?.button_color}`,
                                              color:`${themeData?.button_font_color}`,
                                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                              fontWeight:600,
                                              '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                '& .ads-click-icon': {
                                                  color: `${themeData?.button_color}`,
                                                }
                                              }
                                            }} 
                                            href={`${order_now_urls?.url}`}
                                          >
                                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                                            Order Now</Button>
                                        ) : null}
                                      </Box>
                                    ))}
                                  </DialogContentText>
                                  </DialogContent>
                              </Dialog>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            ))}
        </>
    );
    const locationDataSm = (
        <>
        <Grid item xs={12} sm={12} sx={{height:'100%'}}>
            <Carousel type="LocationCarousel" homeDate={homeDate} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength} alldata={alldata}/>
        </Grid>
        </>
    );
    if (substoredEncryptedKey == suboriginalKey) {
        return (
            <React.Fragment>
                {substoredEncryptedKey == suboriginalKey && (
                    <React.Fragment>
                        <Box
                            sx={{
                                backgroundImage:`url(${homeDate.location_bg})`,
                                boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                                backgroundPosition:'center',
                                backgroundSize:'cover',
                                backgroundRepeat:'no-repeat',
                                height:'450px',
                                padding:`${homeDate.section_border_top_type==2 || homeDate.section_border_top_type==3 ? `40px 0`:`0`}`,
                                position:'relative'
                            }}
                            >
                            <Box 
                                sx={{
                                    height:'20%',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'
                                }}
                            >
                            { homeDate.action == 'show' && (
                                <Typography 
                                    variant="h4" 
                                    dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                            )}
                            </Box>
                            <Grid 
                                container
                                sx={{
                                    height:'80%',
                                    alignItems:'center'
                                }}>
                                {windowWidth < 900 || maxSteps >= 3 ? locationDataSm : locationData}
                            </Grid>
                        </Box>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }else{
        return null;
    }
}

export default Location;

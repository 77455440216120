
import React from 'react';
import { useTheme } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container } from '@mui/material';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from '@mui/material/ButtonGroup';
import './Gallery.css';
import { suboriginalKey } from './responsiveGallery';
import { responsiveGallery } from './responsiveGallery';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Gallery = ({homeDate,substoredEncryptedKey, mainTheme})=> {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();
  const windowWidth = width;
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleClickOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const maxSteps = homeDate?.data?.length;
  const handleNext = () => {
    setSelectedIndex((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setSelectedIndex((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (index) => {
    setSelectedIndex(index);
  };
  const galleryLength = homeDate.data.length;
  if (substoredEncryptedKey == suboriginalKey) {
    return (
      <React.Fragment>
        {substoredEncryptedKey == suboriginalKey && (
          <React.Fragment>
            {mainTheme?.layout == 1 && (
              <Container sx={{position:'relative'}}>
                <Box id='gallery' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',left:'0'}}></Box>
                <ImageList cols={windowWidth < 900 ? windowWidth < 600 ? 1 : 2: 3}>
                  {homeDate.data?.map((item,index) => (
                    <>
                      {(windowWidth < 900 ? windowWidth < 600 ? index < 10 : index < 10 : index < 9) && (
                        <ImageListItem className='gallery_theme1_hover' key={item.img} cols={1} rows={1} onClick={() => handleClickOpen(index)}>
                          <img
                            src={item.image}
                            alt={item.title}
                            loading="lazy"
                            style={{height:'319px'}}
                            height={319}
                          />
                          <ImageListItemBar
                            title={item.title}
                            className='gallery_theme1_text'
                          />
                        </ImageListItem>
                      )}
                    </>
                  ))}
                </ImageList>
              </Container>
            )}
            {mainTheme?.layout == 2 && (
              <Box sx={{position:'relative'}}>
                <Box id='gallery' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',left:'0'}}></Box>
                <ImageList  
                    className="gallery"
                    variant="quilted"
                    cols={windowWidth < 900 ? 2 : 5}
                    sx={{
                      p:0,
                      m:0,
                      overflow:'hidden',
                    }}
                    >
                    {homeDate.data?.map((item,index) => (
                        <ImageListItem 
                        className="gallery_img" 
                        key={item.image}
                        sx={{
                          m:0,
                          p:0,
                          overflow:'hidden',
                          boxShadow:'0 2px 5px 0 rgba(0, 0, 0, .2)'
                        }}
                        cols={
                          windowWidth < 900
                            ? (index === 0 || index === 7)
                              ? item.cols || 2
                              : item.cols || 1
                            : (index === 0 || index === 10)
                              ? item.cols || 2
                              : item.cols || 1
                        }
                        rows={
                          windowWidth < 900
                            ? (index === 0 || index === 7)
                              ? item.rows || 2
                              : item.rows || 1
                            : (index === 0 || index === 10)
                              ? item.rows || 2
                              : item.rows || 1
                        }
                        onClick={() => handleClickOpen(index)}
                        >
                        <img
                          src={item.image}
                          alt={item.title}
                          title={item.title}
                          style={{ margin: 0, padding: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                          loading="lazy"
                        />
                        </ImageListItem>
                    ))}
                </ImageList>
              </Box>
            )}
            {mainTheme?.layout == 3 && (
              <Container sx={{position:'relative'}}>
                <Box id='gallery' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',left:'0'}}></Box>
                <ImageList
                  className='gallery_theme3'
                  variant="quilted"
                  cols={7}
                  rowHeight={121}
                >
                  {homeDate.data?.map((item , index) => (
                    <>
                      {/* {index < 10 && ( */}
                      {(windowWidth < 900 ? windowWidth < 600 ? index < 9 : index < 10 : index < 10) && (
                        <ImageListItem key={item.img} 
                        className="gallery_theme3_hover" 
                        cols={
                          windowWidth < 900
                            ? windowWidth < 600 
                              ? (index === 0 || index === 4 || index === 6 || index === 10 || index === 12)
                                ? item.cols || 3
                                : item.cols || 4
                              :(index === 0 || index === 5)
                                ? item.cols || 2
                                : (index === 1)
                                  ? item.cols || 2 
                                : (index === 4) 
                                  ?  item.cols || 2 
                                : (index === 6)
                                  ? item.cols || 2 
                                : (index === 8) 
                                  ?  item.cols || 2 
                                :item.cols || 3
                            : (index === 0 || index === 5)
                              ? item.cols || 2
                              : (index === 1)
                                ? item.cols || 2 
                              : (index === 4) 
                                ?  item.cols || 2 
                              : (index === 6)
                                ? item.cols || 2 
                              : (index === 8) 
                                ?  item.cols || 2 
                              :item.cols || 3
                        }
                        rows={
                          windowWidth < 900
                            ? windowWidth < 600
                              ? (index === 0 || index === 4 || index === 6 || index === 10 || index === 12)
                                ? item.rows || 4
                                : item.rows || 2
                              :(index === 0 || index === 5)
                                ? item.rows || 2
                                :item.rows || 1
                            : (index === 0 || index === 5)
                              ? item.rows || 4
                              :item.rows || 2
                        }
                        onClick={() => handleClickOpen(index)}>
                          <img
                            src={item.image}
                            alt={item.title}
                            title={item.title}
                            loading="lazy"
                          />
                          <Box
                            className='gallery_theme3_text'>
                              <SearchIcon sx={{margin:'auto',fontSize:'36px',fontWeight:700,color:'#fff'}}/>
                            <Typography sx={{margin:'auto',fontSize:'36px',fontWeight:700,color:'#fff'}}>{item.title}</Typography>
                          </Box>
                        </ImageListItem>
                      )}
                    </>
                  ))}
                </ImageList>
              </Container>
            )}
            {mainTheme?.layout == 4 && (
              <Box sx={{position:'relative'}}>
                <Box id='gallery' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',left:'0'}}></Box>
                <ImageList 
                  cols={
                    windowWidth < 1554
                      ? windowWidth < 966 
                        ? windowWidth < 636
                          ? 2
                          : 10
                        : 9
                      :10
                    }
                  rowHeight={150}
                  sx={{p:1,width:windowWidth < 1554 ? windowWidth < 966 ? windowWidth < 636 ? 'auto' : 'auto': 'auto' : 'auto',margin:'auto'}}>
                  {homeDate.data?.map((item ,index) => (
                    <>
                    {windowWidth < 966 ? 
                      <>
                        {index >= 9 && index <= 10 ? (
                          <ImageListItem
                            sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                            className='gallery_theme4_hover'
                            key={item.img}
                            cols={
                              windowWidth < 1554
                                ? windowWidth < 966
                                  ? windowWidth < 636
                                  ? 1
                                  : 5
                                  : 3
                                : 2
                              }
                            rows={1}
                            onClick={() => handleClickOpen(index)}>
                            <img
                              src={item.image}
                              alt={item.title}
                              title={item.title}
                              loading="lazy"
                              width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                              height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                            />
                            <Box
                                className='gallery_theme4_text'>
                                <Typography 
                                className='gallery_theme4_text_title'
                                  sx={{
                                    margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                    backgroundColor:'#00000094',
                                    width: '100%',
                                    padding:'10px',
                                  }}>
                                    {item.title}
                                </Typography>
                              </Box>
                          </ImageListItem>
                        ):index >= 7 && index <= 8 ? (
                          <ImageListItem
                            sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                            className='gallery_theme4_hover'
                            key={item.img}
                            cols={
                              windowWidth < 1554
                                ? windowWidth < 966
                                  ? windowWidth < 636
                                  ? 1
                                  : 5
                                  : 3
                                : 2
                              }
                            rows={1}
                            onClick={() => handleClickOpen(index)}>
                            <img
                              src={item.image}
                              alt={item.title}
                              title={item.title}
                              loading="lazy"
                              width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                              height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                            />
                            <Box
                                className='gallery_theme4_text'>
                                <Typography 
                                className='gallery_theme4_text_title'
                                  sx={{
                                    margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                    backgroundColor:'#00000094',
                                    width: '100%',
                                    padding:'10px',
                                  }}>
                                    {item.title}
                                </Typography>
                              </Box>
                          </ImageListItem>
                        ):index >= 5 && index <= 6 ? (
                          <ImageListItem
                            sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                            className='gallery_theme4_hover'
                            key={item.img}
                            cols={
                              windowWidth < 1554
                                ? windowWidth < 966
                                  ? windowWidth < 636
                                  ? 1
                                  : 5
                                  : 3
                                : 2
                              }
                            rows={1}
                            onClick={() => handleClickOpen(index)}>
                            <img
                              src={item.image}
                              alt={item.title}
                              title={item.title}
                              loading="lazy"
                              width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                              height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                            />
                            <Box
                                className='gallery_theme4_text'>
                                <Typography 
                                className='gallery_theme4_text_title'
                                  sx={{
                                    margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                    backgroundColor:'#00000094',
                                    width: '100%',
                                    padding:'10px',
                                  }}>
                                    {item.title}
                                </Typography>
                              </Box>
                          </ImageListItem>
                        ):index >= 3 && index <= 4 ? (
                          <ImageListItem
                            sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                            className='gallery_theme4_hover'
                            key={item.img}
                            cols={
                              windowWidth < 1554
                                ? windowWidth < 966
                                  ? windowWidth < 636
                                  ? 1
                                  : 5
                                  : 3
                                : 2
                              }
                            rows={1}
                            onClick={() => handleClickOpen(index)}>
                            <img
                              src={item.image}
                              alt={item.title}
                              title={item.title}
                              loading="lazy"
                              width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                              height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                            />
                            <Box
                                className='gallery_theme4_text'>
                                <Typography 
                                className='gallery_theme4_text_title'
                                  sx={{
                                    margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                    backgroundColor:'#00000094',
                                    width: '100%',
                                    padding:'10px',
                                  }}>
                                    {item.title}
                                </Typography>
                              </Box>
                          </ImageListItem>
                        ):index < 2 ? (
                          <ImageListItem
                            sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                            className='gallery_theme4_hover'
                            key={item.img}
                            cols={
                              windowWidth < 1554
                                ? windowWidth < 966
                                  ? windowWidth < 636
                                  ? 1
                                  : 5
                                  : 3
                                : 2
                              }
                            rows={1}
                            onClick={() => handleClickOpen(index)}>
                            <img
                              src={item.image}
                              alt={item.title}
                              title={item.title}
                              loading="lazy"
                              width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                              height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                            />
                            <Box
                                className='gallery_theme4_text'>
                                <Typography 
                                className='gallery_theme4_text_title'
                                  sx={{
                                    margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                    backgroundColor:'#00000094',
                                    width: '100%',
                                    padding:'10px',
                                  }}>
                                    {item.title}
                                </Typography>
                              </Box>
                          </ImageListItem>
                        ):null}
                      </>
                    :
                      <>
                      {index < 9 && (
                        <>
                          {index < 9 && galleryLength != 7 && galleryLength != 8 && galleryLength != 6 && galleryLength != 5 && galleryLength != 4 && galleryLength != 3 ? (
                            <ImageListItem
                              sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                              className='gallery_theme4_hover'
                              key={item.img}
                              cols={
                                windowWidth < 1554
                                  ? windowWidth < 966
                                    ? windowWidth < 636
                                    ? 1
                                    : 5
                                    : 3
                                  : 2
                                }
                              rows={1}
                              onClick={() => handleClickOpen(index)}>
                              <img
                                src={item.image}
                                alt={item.title}
                                title={item.title}
                                loading="lazy"
                                width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                                height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                              />
                              <Box
                                  className='gallery_theme4_text'>
                                  <Typography 
                                  className='gallery_theme4_text_title'
                                    sx={{
                                      margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                      backgroundColor:'#00000094',
                                      width: '100%',
                                      padding:'10px',
                                    }}>
                                      {item.title}
                                  </Typography>
                                </Box>
                            </ImageListItem>
                          ):index < 6 && galleryLength != 5 && galleryLength != 4 ? (
                            <ImageListItem
                              sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                              className='gallery_theme4_hover'
                              key={item.img}
                              cols={
                                windowWidth < 1554
                                  ? windowWidth < 966
                                    ? windowWidth < 636
                                    ? 1
                                    : 5
                                    : 3
                                  : 2
                                }
                              rows={1}
                              onClick={() => handleClickOpen(index)}>
                              <img
                                src={item.image}
                                alt={item.title}
                                title={item.title}
                                loading="lazy"
                                width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                                height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                              />
                              <Box
                                  className='gallery_theme4_text'>
                                  <Typography 
                                  className='gallery_theme4_text_title'
                                    sx={{
                                      margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                      backgroundColor:'#00000094',
                                      width: '100%',
                                      padding:'10px',
                                    }}>
                                      {item.title}
                                  </Typography>
                                </Box>
                            </ImageListItem>
                          ):index < 3 ? (
                            <ImageListItem
                              sx={{width:windowWidth < 636 ? 'auto !important' :'auto !important',height:windowWidth < 636 ? '150px !important' : '304px !important',margin:'auto',}}
                              className='gallery_theme4_hover'
                              key={item.img}
                              cols={
                                windowWidth < 1554
                                  ? windowWidth < 966
                                    ? windowWidth < 636
                                    ? 1
                                    : 5
                                    : 3
                                  : 2
                                }
                              rows={1}
                              onClick={() => handleClickOpen(index)}>
                              <img
                                src={item.image}
                                alt={item.title}
                                title={item.title}
                                loading="lazy"
                                width={windowWidth < 636 ? '160px !imponent' :'304px !imponent'}
                                height={windowWidth < 636 ? '160px !imponent' : '304px !imponent'}
                              />
                              <Box
                                  className='gallery_theme4_text'>
                                  <Typography 
                                  className='gallery_theme4_text_title'
                                    sx={{
                                      margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff',
                                      backgroundColor:'#00000094',
                                      width: '100%',
                                      padding:'10px',
                                    }}>
                                      {item.title}
                                  </Typography>
                                </Box>
                            </ImageListItem>
                          ):null}
                        </>
                      )}
                      </>
                    }
                    </>
                  ))}
                </ImageList>
              </Box>
            )}
            {mainTheme?.layout == 5 && (
              <Box sx={{py:5,position:'relative'}}>
                <Box id='gallery' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',left:'0'}}></Box>
                <Carousel 
                  responsive={responsiveGallery}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  mainTheme={mainTheme}
                  >
                  {homeDate.data?.map((item ,index) => (
                    index < 10 && (
                      <ImageListItem className='gallery_theme5_hover' key={item.img} onClick={() => handleClickOpen(index)} style={{margin:'10px',borderRadius:'5px'}}>
                        <img
                          src={item.image}
                          alt={item.title}
                          title={item.title}
                          style={{height:'319px'}}
                          height={319}
                          loading="lazy"
                        />
                        <Box
                            className='gallery_theme5_text'>
                            <Typography sx={{display:'flex',margin:'auto',fontSize:'18px',fontWeight:700,color:'#fff'}}><SearchIcon/>{item.title}</Typography>
                          </Box>
                      </ImageListItem>
                    )
                  ))}
                </Carousel>
              </Box>
            )}
            <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  {mainTheme.layout == 1 ? (
                      <Button sx={{
                          position:'absolute',
                          zIndex:10,
                          right:'5px',
                          top:'5px',
                          borderRadius:'5px',
                          padding:0,
                          margin:0,
                          maxWidth:'30px',
                          minWidth:'30px',
                          width:'30px',
                          height:'30px',
                          boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                      }} 
                      onClick={handleClose}
                      color='error' 
                      variant='contained'>
                          <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                      </Button>
                  ) : null}
                  {mainTheme.layout == 2 ? (
                    <Button 
                      sx={{
                          position:'absolute',
                          zIndex:10,
                          right:'5px',
                          top:'5px',
                          borderRadius:'50%',
                          maxWidth:'30px',
                          minWidth:'30px',
                          padding:0,
                          margin:0,
                          width:'30px',
                          height:'30px',
                          boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                      }} 
                      onClick={handleClose}
                      color='error' 
                      variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                  ) : null }
                  {mainTheme.layout == 3 ? (
                    <Button sx={{
                        position:'absolute',
                        zIndex:10,
                        right:'5px',
                        top:'5px',
                        borderRadius:'5px',
                        padding:0,
                        margin:0,
                        maxWidth:'30px',
                        minWidth:'30px',
                        width:'30px',
                        height:'30px',
                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleClose}
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                  ) : null}
                  {mainTheme.layout == 4 ? (
                    <Button 
                    sx={{
                        position:'absolute',
                        zIndex:10,
                        right:'5px',
                        top:'5px',
                        borderRadius:'50%',
                        maxWidth:'30px',
                        minWidth:'30px',
                        padding:0,
                        margin:0,
                        width:'30px',
                        height:'30px',
                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleClose}
                    color='error' 
                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                  ) : null}
                  {mainTheme.layout == 5 ? (
                    <Button sx={{
                        position:'absolute',
                        zIndex:10,
                        right:'5px',
                        top:'5px',
                        borderRadius:'5px',
                        padding:0,
                        margin:0,
                        maxWidth:'30px',
                        minWidth:'30px',
                        width:'30px',
                        height:'30px',
                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleClose}
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                  ) : null}
                  <Box sx={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent: 'center',position:'relative',height:'100%'}}>
                    <Paper
                      square
                      elevation={0}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:'center',
                        width:'100%',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                        position:'relative'
                      }}
                    >
                      <Typography>{homeDate.data[selectedIndex].title}</Typography>
                    </Paper>
                    <AutoPlaySwipeableViews
                      className='gallery_silder'
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={selectedIndex}
                      onChangeIndex={handleStepChange}
                      enableMouseEvents
                      selectedItem={selectedIndex}
                      onChange={(index) => setSelectedIndex(index)}
                      style={{height:'100%',width:'100%'}}
                    >
                      {homeDate.data?.map((item, index) => (
                          <div key={index}>
                            <Box sx={{backgroundImage:`url(${item.image})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover',width: '100%', height: '70vh'}}></Box>
                          </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    {mainTheme?.layout == 1 && (
                      <MobileStepper
                        className='banner_dots_theme1'
                        variant="progress"
                        steps={10}
                        position="static"
                        activeStep={selectedIndex}
                        sx={{ maxWidth: 400, flexGrow: 1 }}
                        nextButton={
                          <Button size="small" onClick={handleNext} disabled={selectedIndex === 9}>
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <ArrowCircleRightIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ) : (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                              </>
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={selectedIndex === 0}>
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <ArrowCircleLeftIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ):(
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                              </>
                            )}
                          </Button>
                        }
                      />
                    )}
                    {mainTheme?.layout == 2 && (
                      <MobileStepper
                        className='gallery_dots'
                        steps={maxSteps}
                        position="static"
                        activeStep={selectedIndex}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={selectedIndex === maxSteps - 1}
                          >
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <ArrowCircleRightIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ) : (
                              <>
                                Next
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                              </>
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={selectedIndex === 0}>
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <ArrowCircleLeftIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ):(
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                              </>
                            )}
                          </Button>
                        }
                      />
                    )}
                    {mainTheme?.layout == 3 && (
                      <MobileStepper
                        className='banner_dots_theme3'
                        variant="text"
                        steps={10}
                        position="static"
                        activeStep={selectedIndex}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={selectedIndex === 9}
                          >
                            {windowWidth < 600 ? (
                              theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <ArrowCircleRightIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                              )
                            ) : (
                              <>
                                Next
                                {theme.direction === 'rtl' ? (
                                  <KeyboardArrowLeft />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </>
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={selectedIndex === 0}>
                            {windowWidth < 600 ? (
                              theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                              ) : (
                                <ArrowCircleLeftIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                              )
                            ) : (
                              <>
                                {theme.direction === 'rtl' ? (
                                  <KeyboardArrowRight />
                                ) : (
                                  <KeyboardArrowLeft />
                                )}
                                Back
                              </>
                            )}
                          </Button>
                        }
                      />
                    )}
                    {mainTheme?.layout == 4 && (
                      <MobileStepper
                        className='banner_dots_theme4'
                        variant="progress"
                        steps={10}
                        position="static"
                        activeStep={selectedIndex}
                        sx={{ maxWidth: 400, flexGrow: 1 }}
                        nextButton={
                          <Button size="small" onClick={handleNext} disabled={selectedIndex === 9}>
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <ArrowCircleRightIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ) : (
                              <>
                                Next
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                              </>
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={selectedIndex === 0}>
                            {windowWidth < 600 ? (
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <ArrowCircleLeftIcon sx={{fill:'black',stroke:'white'}} fontSize='large'/>
                                )}
                              </>
                            ):(
                              <>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                              </>
                            )}
                          </Button>
                        }
                      />
                    )}
                    {mainTheme?.layout == 5 && (
                      <MobileStepper
                        className={'banner_dots_theme5'}
                        steps={10}
                        position="static"
                        activeStep={selectedIndex}
                        nextButton={
                          <Button size="small" onClick={handleNext} disabled={selectedIndex === 9}>
                            {windowWidth < 600 ? (
                              theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <ArrowCircleRightIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                              )
                            ) : (
                              <>
                                Next
                                {theme.direction === 'rtl' ? (
                                  <KeyboardArrowLeft />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </>
                            )}
                          </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={selectedIndex === 0}>
                            {windowWidth < 600 ? (
                              theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                              ) : (
                                <ArrowCircleLeftIcon sx={{ fill: 'black', stroke: 'white' }} fontSize="large" />
                              )
                            ) : (
                              <>
                                {theme.direction === 'rtl' ? (
                                  <KeyboardArrowRight />
                                ) : (
                                  <KeyboardArrowLeft />
                                )}
                                Back
                              </>
                            )}
                          </Button>
                        }
                      />
                    )}
                  </Box>
            </Dialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }else{
    return null;
  }
}

export default Gallery;

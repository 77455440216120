import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const currentDate = new Date();
export const currentDay = days[currentDate.getDay()];
const days1 = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const currentDate1 = new Date();
export const currentDay1 = days1[currentDate1.getDay()];
export const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
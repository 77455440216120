import React from "react";
import { Outlet, Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { suboriginalKey } from "./constourStory";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
const limitText = (text, limit) => {
  const words = text.split(' ');
  const trimmed = words.slice(0, limit).join(' ');
  return trimmed + (words?.length > limit ? '...' : '');
};

const OurStory = ({ homeDate, themeData, allLocationNavigtion, substoredEncryptedKey, mainTheme}) => {
  if (substoredEncryptedKey == suboriginalKey) {
    return (
      <React.Fragment>
        {substoredEncryptedKey == suboriginalKey && (
          <Box sx={{position:'relative'}}>
            <Box id='Our_story' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
            <Box
                sx={{
                    backgroundColor: `${homeDate.data.bgcolor}`, 
                    height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}
                }}>
              <Grid 
                sx={{
                  height:{xs:'auto',sm:'auto',md:'480px',lg:'480px'}
                }}
                container
                >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box 
                    sx={{
                        backgroundImage:{
                            xs:`url(${homeDate.data.image_medium})`,
                            sm:`url(${homeDate.data.image_medium})`,
                            md:`url(${homeDate.data.image})`,
                            lg:`url(${homeDate.data.image})`
                        },
                        backgroundPosition:'center',
                        backgroundSize:'cover',
                        backgroundRepeat:'no-repeat',
                        height:{xs:'300px',sm:'300px',md:'480px',lg:'480px'}
                    }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                        height:{xs:'auto',sm:'400px',md:'480px',lg:'480px'},
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        padding:'50px'
                    }}
                    >
                    { homeDate.action == 'show' && (
                      <Box sx={{ my: 1 }}>
                        <Typography
                          sx={{ textAlign: 'center' }}
                          variant="h4"
                          dangerouslySetInnerHTML={{ __html: homeDate?.title }} />
                      </Box>
                    )}
                    <Box sx={{ my: 1 }}>
                      <Typography
                        sx={{ textAlign: 'center' }}
                        variant="h6"
                        dangerouslySetInnerHTML={{ __html: limitText(homeDate?.data?.content, 60) }}
                      />
                    </Box>
                    <Box sx={{ my: 1 }}>
                      <Link to={`/${allLocationNavigtion}/readmore`}>
                        {mainTheme?.layout == 5 && (
                          <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled button group"
                          >
                              <Button
                                  sx={{
                                      my:1.5,
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      fontWeight:600,
                                      '&:hover': {
                                      backgroundColor:`${themeData?.button_font_color}`,
                                      color:`${themeData?.button_color}`,
                                      border:`1px solid ${themeData?.button_color}`,
                                      borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      }
                                  }}
                              >
                                  READ MORE
                              </Button>
                              <Button color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                          </ButtonGroup>
                          )}
                          {mainTheme.layout == 1 ? (
                            <Button 
                              sx={{
                              backgroundColor:`${themeData?.button_color}`,
                              color:`${themeData?.button_font_color}`,
                              borderRadius:'50px',
                              fontWeight:600,
                              '&:hover': {
                                  backgroundColor:'transparent',
                                  color:`${themeData?.button_color}`,
                                  border:`1px solid ${themeData?.button_color}`,
                                  borderRadius:'50px',
                              }
                              }}>
                                READ MORE
                            </Button>
                          ) : null}
                          {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                            <Button 
                                sx={{
                                backgroundColor:`${themeData?.button_color}`,
                                color:`${themeData?.button_font_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                fontWeight:600,
                                '&:hover': {
                                    backgroundColor:'transparent',
                                    color:`${themeData?.button_color}`,
                                    border:`1px solid ${themeData?.button_color}`,
                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                    '& .ads-click-icon': {
                                      color: `${themeData?.button_color}`,
                                    }
                                }
                                }}>
                                {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                READ MORE
                              </Button>
                          ) : null}
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </React.Fragment>
    );
  }else{
    return null;
  }
};

export default OurStory;

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
import './MiniBanner.css';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
const MiniBannerBottom = ({homeDate,allLocationNavigtion,mainTheme,themeData}) => {
    const { height, width } = useWindowDimensions();
    const windowWidth = width;
    const spans = document.querySelectorAll('.minibanerFontSise span');
    spans.forEach(span => {
        if (window.getComputedStyle(span).fontSize === '62px') {
            span.classList.add('spanFontSize62pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '32px') {
            span.classList.add('spanFontSize32pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '36px') {
            span.classList.add('spanFontSize36pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '40px') {
            span.classList.add('spanFontSize40pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '48px') {
            span.classList.add('spanFontSize48pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '72px') {
            span.classList.add('spanFontSize72pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '78px') {
            span.classList.add('spanFontSize78pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '82px') {
            span.classList.add('spanFontSize82pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '88px') {
            span.classList.add('spanFontSize88pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '96px') {
            span.classList.add('spanFontSize96pxrespo');
        }
        if (window.getComputedStyle(span).fontSize === '100px') {
            span.classList.add('spanFontSize100pxrespo');
        }
    });
    return(
        <Box sx={{position: 'relative',top: 0,zIndex: 10,backgroundColor:`${homeDate.minibanner_bottom.banner_bg_color}`,height:'226px',display:'flex',alignItems:'center',justifyContent:'center'}}>
            {homeDate.minibanner_bottom && homeDate.minibanner_bottom.top_image_border && (
                <img
                    className="minibannerimagetop"
                    src={`${homeDate.minibanner_bottom.top_image_border}`}
                    alt='mini banner top is not found'
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        width:'100%',
                        height: windowWidth < 600 ? 'auto' : '30px',
                        transform: `rotate(0deg)`,
                    }}
                    loading="lazy"
                />
            )}
            <Box>
                <Typography
                    className="minibanerFontSise"
                    sx={{textAlign:'center'}}
                    dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.banner_title }}
                />
                <Box sx={{textAlign:'center'}}>
                    {homeDate.minibanner_bottom.buttom_show_hide == 'show' && (
                        <React.Fragment>
                            {mainTheme?.layout == 5 && (
                                <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled button group"
                                >
                                    <Button
                                    href={homeDate.minibanner_bottom.button_url === '' || homeDate.minibanner_bottom.button_url === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url}`}
                                    sx={{
                                        ml:1,
                                        my:1.5,
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        // color:`${themeData?.button_font_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        fontWeight:600,
                                        '&:hover': {
                                        backgroundColor:'transparent',
                                        color:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        border:`2px solid ${homeDate.minibanner_bottom.button_bg_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        }
                                    }}
                                    >
                                        {homeDate.minibanner_bottom.button_text === '' || homeDate.minibanner_bottom.button_text === null ? (<Typography>Button</Typography>):(<Typography className="minibaner_btn_text" dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text }}/>)}
                                    </Button>
                                    <Button href={homeDate.minibanner_bottom.button_url === '' || homeDate.minibanner_bottom.button_url || null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url}`} color='warning' sx={{my:1.5,mr:1,}}><AdsClickIcon /></Button>
                                </ButtonGroup>
                            )}
                            {mainTheme.layout == 1 ? (
                                <Button 
                                    href={homeDate.minibanner_bottom.button_url === '' || homeDate.minibanner_bottom.button_url === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url}`}
                                    sx={{
                                        borderRadius:'50px',
                                        mx:1,
                                        my:1.5,
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                        '&:hover':{
                                            color:`${homeDate.minibanner_bottom.button_bg_color}`,
                                            border:`1px solid ${homeDate.minibanner_bottom.button_bg_color}`,
                                            borderRadius:'50px',
                                        }
                                    }}>
                                        {homeDate.minibanner_bottom.button_text === '' || homeDate.minibanner_bottom.button_text === null ? (<Typography>Button</Typography>):(<Typography className="minibaner_btn_text" dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text }}/>)}
                                </Button>
                            ) : null}
                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                <Button 
                                    href={homeDate.minibanner_bottom.button_url === '' || homeDate.minibanner_bottom.button_url === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url}`}
                                    sx={{
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        mx:1,
                                        my:1.5,
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                        '&:hover':{
                                            color:`${homeDate.minibanner_bottom.button_bg_color}`,
                                            border:`1px solid ${homeDate.minibanner_bottom.button_bg_color}`,
                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                            '& .ads-click-icon': {
                                               color: `${homeDate.minibanner_bottom.button_bg_color}`,
                                            }
                                        }
                                    }}>
                                        {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                        {homeDate.minibanner_bottom.button_text === '' || homeDate.minibanner_bottom.button_text === null 
                                            ?(<Typography className="ads-click-icon">Button</Typography>)
                                            :(<Typography className="ads-click-icon minibaner_btn_text" dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text }}/>)
                                        }
                                </Button>
                            ) : null}
                        </React.Fragment>
                    )}
                    {homeDate.minibanner_bottom.buttom_show_hide_two == 'show'&& (
                        <>
                            {mainTheme?.layout == 5 && (
                                <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled button group"
                                >
                                    <Button
                                    href={homeDate.minibanner_bottom.button_url_two === '' || homeDate.minibanner_bottom.button_url_two === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url_two}`}
                                    sx={{
                                        ml:1,
                                        my:1.5,
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        fontWeight:600,
                                        '&:hover': {
                                        backgroundColor:'transparent',
                                        color:`${homeDate.minibanner_bottom.button_bg_color}`,
                                        border:`2px solid ${homeDate.minibanner_bottom.button_bg_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        }
                                    }}
                                    >
                                        {homeDate.minibanner_bottom.button_text_two === '' || homeDate.minibanner_bottom.button_text_two === null ? (<Typography>Button</Typography>):(<Typography className="minibaner_btn_text" dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text_two }}/>)}
                                    </Button>
                                    <Button href={homeDate.minibanner_bottom.button_url_two === '' || homeDate.minibanner_bottom.button_url_two === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url_two}`} color='warning' sx={{my:1.5,mr:1}}><AdsClickIcon /></Button>
                                </ButtonGroup>
                            )}
                            {mainTheme.layout == 1 ? (
                                <Button 
                                    href={homeDate.minibanner_bottom.button_url_two === '' || homeDate.minibanner_bottom.button_url_two === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url_two}`}
                                    sx={{
                                        mx:1,
                                        my:1.5,
                                        borderRadius:'50px',
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color_two}`,
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                        '&:hover':{
                                            color:`${homeDate.minibanner_bottom.button_bg_color_two}`,
                                            border:`1px solid ${homeDate.minibanner_bottom.button_bg_color_two}`,
                                            borderRadius:'50px',
                                        }
                                    }}>
                                        {homeDate.minibanner_bottom.button_text_two === '' || homeDate.minibanner_bottom.button_text_two === null ? (<Typography>Button</Typography>):(<Typography className="minibaner_btn_text"  dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text_two }}/>)}
                                </Button>
                            ) : null}
                            {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                <Button 
                                    href={homeDate.minibanner_bottom.button_url_two === '' || homeDate.minibanner_bottom.button_url_two === null ? `${allLocationNavigtion}#`:`${homeDate.minibanner_bottom.button_url_two}`}
                                    sx={{
                                        mx:1,
                                        my:1.5,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        backgroundColor:`${homeDate.minibanner_bottom.button_bg_color_two}`,
                                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                                        '&:hover':{
                                            color:`${homeDate.minibanner_bottom.button_bg_color_two}`,
                                            border:`1px solid ${homeDate.minibanner_bottom.button_bg_color_two}`,
                                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                            '& .ads-click-icon': {
                                               color: `${homeDate.minibanner_bottom.button_bg_color_two}`,
                                            }
                                        }
                                    }}>
                                        {mainTheme?.layout == 4 ? <AdsClickIcon className="ads-click-icon" sx={{color:'#000',mx:1}} /> : ''}
                                        {homeDate.minibanner_bottom.button_text_two === '' || homeDate.minibanner_bottom.button_text_two === null 
                                            ?(<Typography className="ads-click-icon">Button</Typography>)
                                            :(<Typography className="ads-click-icon minibaner_btn_text" dangerouslySetInnerHTML={{ __html: homeDate.minibanner_bottom.button_text_two }}/>)
                                        }
                                </Button>
                            ) : null}
                        </>
                    )}
                </Box>
            </Box>
            {homeDate.minibanner_bottom && homeDate.minibanner_bottom.bottom_image_border && (
                <img
                    className="minibannerimagebottom"
                    alt='mini banner bottom is not found'
                    src={`${homeDate.minibanner_bottom.bottom_image_border}`}
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        width:'100%',
                        height: windowWidth < 600 ? 'auto' : '30px',
                        transform: `rotate(180deg)`,
                    }}
                    loading="lazy"
                />
            )}
        </Box>
    );
}
export default MiniBannerBottom;
import React from "react";
import { Box } from "@mui/material";

const Map = ({ defaultCenter }) => {
  const mapStyles = {
    height: "240px",
    width: "100%"
  };
  const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAU2ke32chbkahfPbu3f1b5DkHbDNeVvN4&q=${encodeURIComponent(defaultCenter.lat)},${encodeURIComponent(defaultCenter.lng)}`;
  return (
    <Box sx={{ padding: '0 8px' }}>
      <iframe
        title="Google Map"
        style={mapStyles}
        src={googleMapsUrl}
        frameBorder="0"
        allowFullScreen
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </Box>
  );
};

export default Map;

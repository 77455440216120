import { One } from "../../apiaction/customKey/one";
import { Two } from "../../apiaction/customKey/Two/Two";
import { Three } from "../../apiaction/customKey/Two/Three/Three";
import { Four } from "../../apiaction/customKey/Two/Three/Four/Four";
import { Five } from "../../apiaction/customKey/Two/Three/Four/Five/Five";
import { Six } from "../../apiaction/customKey/Two/Three/Four/Five/Six/Six";
import { Seven } from "../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven";
import { Eight } from "../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Eight";
import { Nine } from "../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine";
import { Ten } from "../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Ten/Ten";
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Twelve } from '../../customHook/Eleven/Twelve/Twelve';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fourteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fourteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Sixteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Sixteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Eighteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Eighteen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { Twenty } from '../Twenty/Twenty';
import { TwentyOne } from '../Twenty/TwentyOne/TwentyoOne';
import { TwentyTwo } from '../Twenty/TwentyOne/TwentyTwo/TwentyTwo';
import { TwentyThree } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFour } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFour';
import { TwentyFive } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySix } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySix';
import { TwentySeven } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyEight } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyEight';
import { TwentyNine } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
import { Thirty } from '../Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/Thirty/Thirty'
export const originalKey = One.concat(Two,Three,Four,Five,Six,Seven,Eight,Nine,Ten,Eleven,Twelve,Thirteen,Fourteen,Fifteen,Sixteen,Seventeen,Eighteen,Nineteen,Twenty,TwentyOne,TwentyTwo,TwentyThree,TwentyFour,TwentyFive,TwentySix,TwentySeven,TwentyEight,TwentyNine,Thirty);
import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
export const responsiveGallery = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1199, min: 900 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 899, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1
    }
};
export const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
import React, { useEffect } from "react";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useLocation } from "react-router-dom";
import Carousel from "../../component/carousel/Carousel";
import { originalKey } from "./constReadMore";
const ReadMore = ({getLocation, headermenu, alldata, themeData, businessHours, carryoutHours, footerData, allLocation, allLocationNavigtion, home, loading, substoredEncryptedKey, storedEncryptedKey, mainTheme, webLocationLength})=> {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    if (loading == false && originalKey == storedEncryptedKey) {
        return(
            <>  
                <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
                {home?.map((homeDate) => (
                    <>
                        {homeDate.section_id == 2 && (
                            <>
                                {homeDate.our_story_all?.map((our_all,index) => (
                                    <>
                                    {(index % 2 == 0) ? (
                                        <>
                                        <Box sx={{backgroundColor:'#000',height:{xs:'auto',sm:'auto',md:'700px',lg:'700px',marginBottom:'5px'}}}>
                                            <Grid container sx={{height:'100%'}}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%'}}>                                              
                                                    <Carousel type="ReadMorePageCarousel" homeDate={our_all} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>                        
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%',display:{xs:'block',sm:'block',md:'block',lg:'block'}}}>
                                                    <Box sx={{alignItems:'center',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'100%'}}>                                                   
                                                        <Box sx={{alignItems:'center',margin:'0px 10px'}}>                                                       
                                                            <Typography variant="h4"
                                                                sx={{textAlign:'center',display:'block',color:'#fff'}} 
                                                                    dangerouslySetInnerHTML={{ __html: our_all.title }}
                                                            /> 
                                                            <Typography
                                                                sx={{textAlign:'center',display:'block',color:'#fff'}} 
                                                                dangerouslySetInnerHTML={{ __html: our_all.description }}
                                                            />                                                                                
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                               
                                            </Grid>
                                        </Box>                                        
                                        </>
                                    ):(
                                        <>
                                       <Box sx={{backgroundColor:'#000',height:{xs:'auto',sm:'auto',md:'700px',lg:'700px',marginBottom:'5px'}}}>
                                            <Grid container sx={{height:'100%'}}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%',display:{xs:'block',sm:'block',md:'block',lg:'block'}}}>
                                                    <Box sx={{alignItems:'center',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'100%'}}>                                                   
                                                        <Box sx={{alignItems:'center',margin:'0px 10px'}}>                                                       
                                                            <Typography variant="h4"
                                                                sx={{textAlign:'center',display:'block',color:'#fff'}} 
                                                                    dangerouslySetInnerHTML={{ __html: our_all.title }}
                                                            /> 
                                                            <Typography
                                                                sx={{textAlign:'center',display:'block',color:'#fff'}} 
                                                                dangerouslySetInnerHTML={{ __html: our_all.description }}
                                                            />                                                                                
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} sx={{height:'100%'}}>                                              
                                                    <Carousel type="ReadMorePageCarousel" homeDate={our_all} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>                        
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        </>
                                    )}
                                    </>
                                ))}
                                </>
                        )}
                    </>
                ))}
                {home?.map((homeDate) => (
                    <>
                    {homeDate.section_id == 2 && (
                        <Box 
                            sx={{
                                backgroundImage:{xs:`url(${homeDate.data.image_medium})`,
                                sm:`url(${homeDate.data.image_medium})`,
                                md:`url(${homeDate.data.image})`,
                                lg:`url(${homeDate.data.image})`},
                                position:'relative',
                                minHeight: {xs:'fit-content',sm:'100vh',md:'456px',lg:'456px'},
                                height:'100%',
                                backgroundAttachment:'fixed',
                                backgroundPosition:'center',
                                backgroundRepeat:'no-repeat',
                                backgroundSize:'cover',
                                display:'flex',alignItems:'center',justifyContent:'center'
                            }}>
                                <Box sx={{position:'absolute',zIndex:1,height:'100%',width:'100%',backgroundColor:'#00000040'}}></Box>
                                <Box 
                                    sx={{
                                        position:{xs:'unset',sm:'absolute',md:'absolute',lg:'absolute'},
                                        zIndex:2,
                                        width:{xs:'90%',sm:'90%',md:'90%',lg:'90%'},
                                        height:'auto',
                                        // backgroundColor:'#000000ad',
                                        backgroundColor: `${homeDate.data.bgcolor}`,
                                        borderRadius:'50px',
                                        p:3,
                                        display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',
                                        boxShadow:'rgb(255 255 255) 0 3px 8px',
                                    }}
                                    >
                                    <Typography
                                            sx={{ textAlign: 'center' }}
                                            variant="h4"
                                            dangerouslySetInnerHTML={{ __html: homeDate.title }} 
                                    />
                                    <Typography
                                        sx={{ textAlign: 'center' }}
                                        variant="h6"
                                        dangerouslySetInnerHTML={{ __html: homeDate.data.content }}
                                    />
                                </Box>
                        </Box>
                    )}
                    </>
                ))}
                <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
            </>
        )
    }else{
        return null;
    }
}

export default ReadMore;
import React, { useEffect, useRef, startTransition } from 'react';
import { Box, IconButton } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link, useLocation } from 'react-router-dom';
import { fetchDefaultLocation } from '../../apiaction/actions';
import { useDispatch, useSelector } from 'react-redux';
import { One } from '../../apiaction/customKey/one';
import { Three } from '../../apiaction/customKey/Two/Three/Three';
import { Five } from '../../apiaction/customKey/Two/Three/Four/Five/Five';
import { Seven } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Seven';
import { Nine } from '../../apiaction/customKey/Two/Three/Four/Five/Six/Seven/Eight/Nine/Nine';
import { Eleven } from '../../customHook/Eleven/Eleven';
import { Thirteen } from '../../customHook/Eleven/Twelve/Thirteen/Thirteen';
import { Fifteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Fifteen';
import { Seventeen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Seventeen';
import { Nineteen } from '../../customHook/Eleven/Twelve/Thirteen/Fourteen/Fifteen/Sixteen/Seventeen/Eighteen/Nineteen/Nineteen';
import { TwentyOne } from '../../pages/Twenty/TwentyOne/TwentyoOne';
import { TwentyThree } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyThree';
import { TwentyFive } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentyFive';
import { TwentySeven } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentySeven';
import { TwentyNine } from '../../pages/Twenty/TwentyOne/TwentyTwo/TwentyThree/TwentyFour/TwentyFive/TwentySix/TwentySeven/TwentyEight/TwentyNine/TwentyNine';
import Skeleton from '@mui/material/Skeleton';
const ITEM_HEIGHT = 48;
const StepperMenu = ({activeStep, allLocationNavigtion, ourMenu, substoredEncryptedKey,webmenuitemData,loading1}) => {
  const suboriginalKey = One.concat(Three,Five,Seven,Nine,Eleven,Thirteen,Fifteen,Seventeen,Nineteen,TwentyOne,TwentyThree,TwentyFive,TwentySeven,TwentyNine);
  // const {
  //   webmenuitemData1,
  //   webmenuitemDataSlotDefault,
  //   webmenuitemDataSlot11,
  //   webmenuitemDataSlot21,
  //   webmenuitemDataSlot31,
  //   loading1,
  // } = useSelector(state => state.api);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   startTransition(() => {
  //     dispatch(fetchDefaultLocation());
  //   });
  // }, [dispatch]);
  const scrollContainerRef = useRef(null);
  const { menuPagePositionTop } = useSelector((state) => state.header);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };
  const location = useLocation()
  let webmenuitemDataSlotAll = webmenuitemData;
  // if(location.pathname === `/${allLocationNavigtion}/menu/slot=1`){
  //   webmenuitemDataSlotAll = webmenuitemDataSlot11;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu/slot=2`){
  //   webmenuitemDataSlotAll = webmenuitemDataSlot21;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu/slot=3`){
  //   webmenuitemDataSlotAll = webmenuitemDataSlot31;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu`){
  //   webmenuitemDataSlotAll = webmenuitemDataSlotDefault;
  // }
  // else{
  //  console.log('Slot is not found');
  // }
  const work_days = webmenuitemDataSlotAll?.restaurant?.work_days;
  const workdays_type_data = webmenuitemDataSlotAll?.restaurant;
  const select_menu = webmenuitemDataSlotAll?.select_menu;
  const get_slot = webmenuitemDataSlotAll?.get_slot;
  const slot_text = webmenuitemDataSlotAll?.slot_text;
  // const getInitialAge = () => {
  //   if(workdays_type_data?.workdays_type == 1){
  //     if (work_days?.is_today !== 3) {
  //       if (work_days?.breakfast_start_hour && work_days?.breakfast_end_hour && location.pathname === `/${allLocationNavigtion}/menu/slot=1`) {
  //         return 1;
  //       } else if (work_days?.lunch_start_hour && work_days?.lunch_end_hour && location.pathname === `/${allLocationNavigtion}/menu/slot=2`) {
  //         return 2;
  //       } else if (work_days?.dinner_start_hour && work_days?.dinner_end_hour && location.pathname === `/${allLocationNavigtion}/menu/slot=3`) {
  //         return 3;
  //       } else {
  //         return !select_menu ? select_menu : !get_slot ? get_slot : 1;
  //       }
  //     }
  //   }else if(workdays_type_data?.workdays_type == 0){
  //     if (work_days?.is_today !== 3) {
  //       return 0;
  //     }
  //   }else{
  //     return 1;
  //   }
  //   return '';
  // };
  // const [age, setAge] = React.useState(getInitialAge());

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  // console.log(age);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handledivScroll = () => {
    setTimeout(() => {
      var about1 = window.location.href;
      var about2 = window.location.href.split("#").pop();
      if(about1 != about2){
        if(about2 != '' || about2.length > 0){
          var about = document.getElementById(about2);
          if (about !== null) {  
              about.scrollIntoView({ behavior: 'smooth',block: "end", inline: "nearest" });             
          }
        }
      }
    }, 100)
  }
  // if (suboriginalKey == substoredEncryptedKey) {
    return (
      <React.Fragment>
        {substoredEncryptedKey == suboriginalKey && (
          <React.Fragment>
            {loading1 ? (<Skeleton variant="rectangular" width="100%" height={106}  className='skeleton skeletonlayput1'/>):(
              <Box 
                  sx={{
                      display:'flex',
                      alignItems:'center',
                      maxWidth:'1368px',
                      width:'95%',
                      margin:'auto',
                      flexDirection:{xs:'column',sm:'column',md:'column',lg:'row'},
                      justifyContent:'center',
                      textAlign:'center',
                      py:{xs:3,sm:3,md:3,lg:2},
                      px:1,
                      position: 'fixed',
                      top: {xs:`${menuPagePositionTop}`,sm:`${menuPagePositionTop}`,md:`${menuPagePositionTop}`,lg:`${menuPagePositionTop}`},
                      backgroundColor: ourMenu.category_box_color,
                      borderTop:'1px solid #c1c1c1',
                      borderBottom:'1px solid #c1c1c1',
                      zIndex:2
                  }}>
                  <Box sx={{Width:'310.766px'}}>
                      {work_days?.is_today !== 3 && (
                        <FormControl sx={{ my: 1, minWidth: '200.766px' }} size="small">
                          {workdays_type_data?.workdays_type == 1 ? (
                            <>
                            <InputLabel id="demo-select-small-label">{slot_text}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label={slot_text}
                                value={select_menu}
                                // onChange={handleChange}
                            >                             
                              {work_days?.breakfast_start_hour && work_days?.breakfast_end_hour && (
                                <MenuItem value={1}>
                                  <Link to={`/${allLocationNavigtion}/menu/slot=1`} style={{textDecoration:'none',color:'#000',fontWeight:select_menu == 1 ? 700 : 500,}}>{work_days?.breakfast_start_hour} - {work_days?.breakfast_end_hour}</Link>
                                </MenuItem>
                              )}
                              {work_days?.lunch_start_hour && work_days?.lunch_end_hour && (
                                <MenuItem value={2}>
                                  <Link to={`/${allLocationNavigtion}/menu/slot=2`} style={{textDecoration:'none',color:'#000',fontWeight:select_menu == 2 ? 700 : 500,}}>{work_days?.lunch_start_hour} - {work_days?.lunch_end_hour}</Link>
                                </MenuItem>
                              )}
                              {work_days?.dinner_start_hour && work_days?.dinner_end_hour && (
                                <MenuItem value={3}>
                                  <Link to={`/${allLocationNavigtion}/menu/slot=3`} style={{textDecoration:'none',color:'#000',fontWeight:select_menu == 3 ? 700 : 500,}}>{work_days?.dinner_start_hour} - {work_days?.dinner_end_hour}</Link>
                                </MenuItem>
                              )}                        
                            </Select>
                            </>
                          ) : (
                          <>
                          <InputLabel id="demo-select-small-label">{slot_text}</InputLabel>
                           <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label={slot_text}
                                value={0}
                                //onChange={handleChange}
                            > 
                              {webmenuitemDataSlotAll?.time && (
                                <MenuItem value={0}>
                                  <Link to={`/${allLocationNavigtion}/menu`} style={{textDecoration:'none',color:'#000',fontWeight:700,}}>{webmenuitemDataSlotAll?.time}</Link>
                                </MenuItem>
                              )}
                             </Select>
                          </>
                        )}
                        </FormControl>
                      )}
                  </Box>
                  <Box className='stepper-main' sx={{display:'flex',alignItems:'center' ,justifyContent:'center'}}>
                    <Button
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            sx={{mx:0.5,backgroundColor:'#000',borderRadius:'20px',minWidth:'165px',margin:'0px 10px'}} variant="contained" startIcon={<RestaurantMenuIcon style={{color:'white'}}/>}>
                        <Typography sx={{fontFamily:'inherit',fontSize:'inherit',fontFamily:700}}>Browse Menu</Typography>
                    </Button>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: '275px',
                            width: '300px',
                            minWidth:'300px',
                            maxWidth: '300px',
                            backgroundColor:'#000',
                            marginTop:'10px',
                            marginLeft:'10px',
                            borderRadius:'12px',
                          },
                        }}
                      >
                        {webmenuitemDataSlotAll?.Category_list?.map((option, index) => (
                          <MenuItem key={option.name} onClick={handleClose} sx={{backgroundColor:'#000'}}>
                            <a href={`#${option.id}`} onClick={ourMenu.design_layout == 2 ? handledivScroll : ''} style={{textDecoration:'none',color:'#fff',width:'100%',fontWeight:700}}>
                              <Box className='long-menu-hove'  sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <Box className={`step ${activeStep === index ? 'long-menu-active long-menu-a' : 'long-menu-a'}`} sx={{fontSize:'14px',fontWeight:400}}>
                                  {option.name}
                                </Box>
                                <Box className={`step ${activeStep === index ? 'long-menu-active long-menu-b' : 'long-menu-b'}`} sx={{fontSize:'14px',fontWeight:400}}>{option.menu_item?.length}</Box>
                              </Box>
                            </a>
                          </MenuItem>
                        ))}
                    </Menu>
                    <div className="stepper-menu" style={{ backgroundColor: ourMenu.category_box_color, alignItems: 'center' }}>
                      <IconButton onClick={scrollLeft} sx={{p:0}}>
                          <ArrowBackIosIcon />
                      </IconButton>
                      <Box className='stepper-menu-scrollContainer' ref={scrollContainerRef} style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
                     
                          {webmenuitemDataSlotAll?.Category_list?.map((Category_list, index) => (
                            <>
                             {ourMenu.design_layout == 2 ? (  
                               <a href={`#${Category_list.id}`} onClick={handledivScroll} style={{textDecoration:'none'}}>                        
                                  <Box
                                    key={Category_list.id}
                                    to={Category_list.id}
                                    smooth={true}
                                    duration={500}
                                    className={`step ${activeStep === index ? 'active' : ''}`}
                                    style={{ color: '#000', margin: '10px', whiteSpace: 'nowrap', padding: '5px', borderBottom: activeStep === index ? '2px solid black' : 'none' }}
                                  >
                                      {Category_list.name}
                                  </Box> 
                                </a>                               
                             ):(
                                <ScrollLink
                                    key={Category_list.id}
                                    to={Category_list.id}
                                    smooth={true}
                                    duration={500}
                                    className={`step ${activeStep === index ? 'active' : ''}`}
                                    style={{ color: '#000', margin: '10px', whiteSpace: 'nowrap', padding: '5px', borderBottom: activeStep === index ? '2px solid black' : 'none' }}
                                >
                                    {Category_list.name}
                                </ScrollLink>
                             )}
                          </>
                          ))}
                      </Box>
                      <IconButton onClick={scrollRight} sx={{p:0}}>
                          <ArrowForwardIosIcon />
                      </IconButton>
                    </div>
                  </Box>
              </Box>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  // }else{
  //   return null;
  // }
};

export default StepperMenu;
